import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REMOVE_ALERT } from "../../actions/types";
import { useDispatch } from "react-redux";
import {
  faXmark,
  faCircleInfo,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const Alert = ({ alerts }) => {
  const dispatch = useDispatch();
  console.log(alerts);
  return (
    <>
      {alerts !== null &&
        alerts.length > 0 &&
        alerts.map((alert, id) => (
          <div key={id} className={`alert alert-${alert.alertType}`}>
            <div className="icon-and-message">
              {alert.alertType === "info" && (
                <FontAwesomeIcon icon={faCircleInfo} size="2x" />
              )}
              {alert.alertType === "danger" && (
                <FontAwesomeIcon icon={faXmark} size="2x" />
              )}
              {alert.alertType ==="success" && (
                <FontAwesomeIcon icon={faCheck} size="2x" />
              )}
              <br></br>
              <span className="message">{alert.msg}</span>

              <br></br>
              {/* <Link
                className="ms-5 float-end me-0 text-light "
                onClick={() => {
                  dispatch({ type: REMOVE_ALERT, payload: alert.uniqueId });
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </Link> */}
            </div>
          </div>
        ))}
    </>
  );
};
Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
