const LoginValidator = (values) =>{
    let errors = '';
    if(!values.email){
        errors = "Email Required"
    }
    else if (!values.email.includes('@' && '.')){
        errors = 'please enter valid mail-id'
    }
    

    if(!values.password) {
        errors = "password required"
    }

    else if(!values.email.includes('@' && '.')){
        errors = "password should contain a special charachter  "
    }

    return errors;
}
export default LoginValidator;