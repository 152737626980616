
const RegisterValidator = (values) =>{

    let errors = '';
    
    if(!values.email && !values.username){
        errors = "Email and username flelds should not be empty"
    }else{
    if(!values.email){
        errors = "Email Required"
    }
    else if (!values.email.includes('@' && '.')){
        errors = 'please enter valid mail-id'
    }
    else{
        console.log(values.email)
    }
    
    if(!values.username) {
        errors = "username required"
    }

    else
    {
        let c = values.username.charAt(0);
    if(c >= '0' && c <= '9'){
        errors = "first letter should not be a number "
    }
    else{
        console.log(values.username)
    }
}
}


    return errors;
}
export default RegisterValidator;