import React,{useEffect, useState} from 'react'

function ConfirmCheckbox({handleConfirm}) {
 const [confirm , setConfirm]= useState(false)
 useEffect(()=>{
handleConfirm(confirm);
 },[confirm])
  return (
    <div className="confirmcheckbox">
        <div className="text-light justify-content-center align-items-center p-5 mt-2 ">
            <h6>Design Review</h6>
            <p className="mb-2">All Set? Let's doubleCheck.</p>
<p>Text: Is it clear and legible?</p>
<p>Margin : Is everything within the space?</p>
<p>Info: Is everythin correct? Spelled correctly ?</p>
<p className="mb-5">Images: Is everything clear (no blur) ?</p>
<br/>
<br/>
<br/>
<br/>
<br/>


<div className="form-check">
  <input className="form-check-input" type="checkbox" value={confirm} id="flexCheckDefault" onChange={()=>setConfirm(!confirm)} />
  <label className="form-check-label" htmlFor="flexCheckDefault">
    I've reviewed and approve my design.
  </label>
</div>

        </div>

    </div>
  )
}

export default ConfirmCheckbox