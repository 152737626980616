import React, { useState , useEffect} from "react";
import ShopHeader from "../layout/ShopHeader";
import Footer from "../layout/Footer";
import CardEditLayout from "../layout/CardEditLayout";
import RightSideBar from "../layout/RightSideBar";
import { useParams } from "react-router-dom";
import axios from "axios";
// import { Link } from "react-router-dom";
// import images from "../../utils/imageImports";
// import FabricEditor from "../ExampleCard";
import MyCanvas from "./Fabric1";
function CardFrontPageEdit() {
  const { color, id } = useParams();
  const [card, setCard] = useState("");
  const [cardSide, setCardSide] = useState("front");

  const background = decodeURIComponent(color);

  useState(() => {
    const fetchCard = async () => {
      try {
        const response = await axios.get(`api/getcards/${id}`);
        setCard(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCard();
  }, []);
useEffect(()=>{
  if(background){
    localStorage.setItem("backgroundColor", JSON.stringify(background))
  }
},[background])
  const handleCardSide = (side) => {
    console.log(`Card side clicked: ${side}`);
    setCardSide(side);
  };
console.log(cardSide);
  return (
    <>
      <ShopHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <CardEditLayout card={card} background={background} />

            {cardSide === "front" && (
              <MyCanvas card={card} background={background} flag="front" />
            )}
            {cardSide === "back" && (
              <MyCanvas background="#000000" card={card} flag="back" />
            )}
            <RightSideBar
              handleCardSide={handleCardSide}
              // cardSide={cardSide} // Pass cardSide to RightSideBar
            />
          </div>
        </div>
      </section>
     
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </>
  );
}

export default CardFrontPageEdit;
