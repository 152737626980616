import axios from "axios";
import { REGISTER_FAIL, REGISTER_SUCCESS, LOGIN_SUCCESS, LOGIN_FAIL, USER_LOADED, AUTH_ERROR , AXIOS_BASE_URL} from "./types";
import { setAlert } from './setAlert';
import setAuthToken from "../utils/setAuthToken";

axios.defaults.baseURL=AXIOS_BASE_URL


export const login = (obj) => async dispatch => {
     const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = obj;
    try {
        const res = await axios.post('users/login', body, config)
       // console.log(res.data);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
dispatch(setAlert(res.data.message,'success'))
      //  console.log(res.data?.token)
    } catch (err) {
        console.log(err.response.data.error)
      //setAlert(errors,'danger');

        dispatch(setAlert(err.response.data.error,'danger'))
        dispatch({
            type: LOGIN_FAIL
        });

    }
}

export const googlelogin = ({  email, googleId, is_verified,  name }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({  email, googleId, is_verified,  name });
    try {
        const res = await axios.post('users/google', body, config);
        console.log(res.data);
        dispatch(setAlert(res.data.message,'success'))
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loaduser());
    } catch (err) {
        console.log(err);
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({ type: LOGIN_FAIL});
    }
}

export const loaduser = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get('users/login' , config);

       console.log(res);
        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
    } catch (err) {
        console.log(err);
        console.log(err.response.data.error)
       // dispatch(setAlert(err.response.data.error, 'danger '))
        dispatch({
            type: AUTH_ERROR

        })
    }
}

export const register = (obj) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = obj;
    try {
        const res = await axios.post('users', body, config)
        console.log(res);
        dispatch(setAlert(res.data, 'info'))
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        console.log(err)
        dispatch(setAlert(err.response.data, 'danger'))
        dispatch({
            type: REGISTER_FAIL
        });

    }
}

// SignUp WITH GOOOGLE
 

export const googleSignUp = ({  email, googleId, is_verified,  name }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({  email, googleId, is_verified,  name });
    try {
        const res = await axios.post('users/google', body , config);
        console.log(res.data);
        dispatch(setAlert(res.data.msg,'success'))
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });
      //  dispatch(loaduser());
    } catch (err) {
        console.log(err);
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({ type: REGISTER_FAIL});
    }
}