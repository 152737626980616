import VCard from "vcard-creator";

const generateVCF =(contactInfo)=>{

    const myVCard = new VCard();
    myVCard
  // Add personal data
  .addName(contactInfo.fullName)
  // Add work data
  
  .addJobtitle(contactInfo.designation)
 
  .addEmail(contactInfo.email)
  .addPhoneNumber(contactInfo.phoneNumber, 'PREF;WORK')
  console.log(myVCard.toString())
  const vcfContent = myVCard.toString();
  console.log(vcfContent)
  const blob = new Blob([vcfContent], { type: 'text/vcard' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'contact.vcf');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export default generateVCF;

