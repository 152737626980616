import React, { useState, useEffect } from "react";
import ShopHeader from "../layout/ShopHeader";
import Footer from "../layout/Footer";
//  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import images from "../../utils/imageImports";
import { Link } from "react-router-dom";
import { getCards } from "../../actions/cards";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AXIOS_BASE_URL } from "../../actions/types";
function VyldCard({ getCards, cards: { cards } }) {
  const [ownDesignCard, setOwnDesignCard] = useState(false);
  const [defaultCard, setDefaultCard] = useState(false);
  const [cardWithImage, setCardWithImage] = useState(false);
  const [totalCards, setTotalCards] = useState([]);
  // const [background, setBackground] = useState("");
  useEffect(() => {
    getCards();
  }, []);
  useEffect(() => {
    setTotalCards(cards.map((card) => ({ ...card, background: "#000000" })));
  }, [cards]);
  console.log(cards);
  const handleColor = (color, index) => {
    const updatedCards = [...totalCards]; // Create a copy of totalCards array
    updatedCards[index].background = color; // Update the background color
    setTotalCards(updatedCards); // Update the state variable
  };
  // console.log(totalCards);
  function getTopPosition(nfcIconPlacement) {
    switch (nfcIconPlacement) {
      case "1":
      case "2":
        return "10px"; // Adjust as needed
      case "3":
      case "4":
        return "calc(100% - 30px)"; // Adjust as needed
      default:
        return "50%"; // Default to center if placement is not defined
    }
  }

  function getLeftPosition(nfcIconPlacement) {
    switch (nfcIconPlacement) {
      case "1":
      case "3":
        return "calc(100% - 306px)"; // Adjust as needed
      case "2":
      case "4":
        return "10px"; // Adjust as needed
      default:
        return "50%"; // Default to center if placement is not defined
    }
  }

  return (
    <div>
      <ShopHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 ">
            <div className="col-md-10">
              <h1>VYLDFYRE Themed NFC Business Card</h1>
              <p className="yellowtext">One from ₹750.00</p>
              <div className="row">
                {cards &&
                  totalCards &&
                  totalCards.map((card, index) => (
                    <>
                      {card.card_type === "1" && (
                        <div
                          key={index}
                          className="col-md-4 pt-3 uploadcard border-end "
                        >
                          <Link
                            to={`/vyldfyrefrontcard/${encodeURIComponent(
                              card.background
                            )}/${card.id}`}
                            onClick={() => {
                              setOwnDesignCard(false);
                              setDefaultCard(true);
                              setCardWithImage(false);
                            }}
                            className="text-light"
                          >
                            {/* <img
                            src={images.vyldfyrenfc}
                            alt="VYLDFYRE branded Card"
                          /> */}
                            {/* <div className="nfc-card">
                          <img className="nfc-card" style={{background : card.background ,}} src={images.vyldfyredesign}   />
                          </div> */}
                            <div
                              className="nfc-card"
                              style={{ position: "relative" }}
                            >
                              {/* Card image */}
                              <img
                                className="nfc-card"
                                style={{ background: card.background }}
                                src={`${AXIOS_BASE_URL}uploads/${card.background_image}`}
                              />

                              {/* Placeholder text */}
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 10,
                                  top: getTopPosition(card.nfc_icon_placement),
                                  right:
                                    ["1", "3"].includes(
                                      card.nfc_icon_placement
                                    ) &&
                                    getLeftPosition(card.nfc_icon_placement), // Right for 1 & 3, otherwise auto
                                  left:
                                    ["2", "4"].includes(
                                      card.nfc_icon_placement
                                    ) &&
                                    getLeftPosition(card.nfc_icon_placement), // Left for 2 & 4, otherwise auto
                                  display: "inline-flex",
                                  alignItems: "center",
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                }}
                              >
                                <span>{card.placeholder_text}</span>{" "}
                                {/* Wrap text in a span */}
                                <img
                                  src={images.nfcsymbol}
                                  style={{
                                    width: "20px",
                                    marginLeft: "5px",
                                    verticalAlign: "middle",
                                  }} // Adjust margin and vertical alignment
                                  alt="NFC Symbol"
                                />
                              </div>
                            </div>
                            <span className="text-white">{card.card_name}</span>
                            <p className="mb-3">
                              {" "}
                              one from ₹ {card.card_price}
                            </p>
                          </Link>
                          <div className="row ms-0">
                            {card.colors.map((color, id) => (
                              <div
                                key={id}
                                onClick={() => {
                                  handleColor(color, index);
                                }}
                                className="colorboxpick col-md-1"
                                style={{ background: color }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
              </div>
              <div className="col-md-10">
              <h1 className="mt-3 mb-3"> plain NFC Business Card</h1>
              <p className="yellowtext">One from ₹750.00</p>
              <div className="row">
                {cards &&
                  totalCards &&
                  totalCards.map((card, index) => (
                    <>
                      {card.card_type === "2" && (
                        <div
                          key={index}
                          className="col-md-4 pt-3 uploadcard border-end "
                        >
                          <Link
                            to={`/vyldfyrefrontcard/${encodeURIComponent(
                              card.background
                            )}/${card.id}`}
                            onClick={() => {
                              setOwnDesignCard(false);
                              setDefaultCard(true);
                              setCardWithImage(false);
                            }}
                            className="text-light"
                          >
                            {/* <img
                            src={images.vyldfyrenfc}
                            alt="VYLDFYRE branded Card"
                          /> */}
                            {/* <div className="nfc-card">
                          <img className="nfc-card" style={{background : card.background ,}} src={images.vyldfyredesign}   />
                          </div> */}
                          
                            <div
                              className="nfc-card"

                              style={{ position:"relative",background: card.background }}

                            >
                             
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 10,
                                  top: getTopPosition(card.nfc_icon_placement),
                                  right:
                                    ["1", "3"].includes(
                                      card.nfc_icon_placement
                                    ) &&
                                    getLeftPosition(card.nfc_icon_placement), // Right for 1 & 3, otherwise auto
                                  left:
                                    ["2", "4"].includes(
                                      card.nfc_icon_placement
                                    ) &&
                                    getLeftPosition(card.nfc_icon_placement), // Left for 2 & 4, otherwise auto
                                  display: "inline-flex",
                                  alignItems: "center",
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                }}
                              >
                                <span>{card.placeholder_text}</span>{" "}
                                {/* Wrap text in a span */}
                                <img
                                  src={images.nfcsymbol}
                                  style={{
                                    width: "20px",
                                    marginLeft: "5px",
                                    verticalAlign: "middle",
                                  }} // Adjust margin and vertical alignment
                                  alt="NFC Symbol"
                                />
                              </div>
                            </div>
                            <span className="text-white">{card.card_name}</span>
                            <p className="mb-3">
                              {" "}
                              one from ₹ {card.card_price}
                            </p>
                          </Link>
                          <div className="row ms-0">
                            {card.colors.map((color, id) => (
                              <div
                                key={id}
                                onClick={() => {
                                  handleColor(color, index);
                                }}
                                className="colorboxpick col-md-1"
                                style={{ background: color }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <br /> <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
VyldCard.propTypes = {
  getCards: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  cards: state.cards,
});
export default connect(mapStateToProps, { getCards })(VyldCard);
