import React ,{useState} from "react";
import RootProvider from "../providers/RootProvider";
const InitialDataContextSetter = ({children}) => {
    const [basicDetailsData, setBasicDetailsData] = useState({ 

    name: "",
    cover: "",
    profile: "",
    designation: "",
    companyName: '',
    mobileNumber: "",
    email: "",
    title: "",
    paragraph: "",
    background: "#102A68",  
  
});

    const updateBasicDetailsData = (newData) => {
      console.log(newData,'newwwwwww');
      
      // Update the basic details data with the new data
      setBasicDetailsData(newData);
    };

  const [socialDetailsData, setSocialDetailsData] = useState([]);


  const [galleryData, setGalleryData] = useState([]);
  

  const contextData = {
    basicDetails: {  data: basicDetailsData , setData: updateBasicDetailsData },
    socialDetails: { data: socialDetailsData, setData: setSocialDetailsData },
    gallery: { data: galleryData, setData: setGalleryData }
  };

  return (
    <RootProvider contextData={contextData} >
        {children}
    </RootProvider>
  );
};

export default InitialDataContextSetter;