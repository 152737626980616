import { GET_GALLERY, PROFILE_ERROR } from "../actions/types";

const initialState = {
    gallery: [],
    loading: true,
    errors: {},
  };

  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_GALLERY:
        return {
          ...state,
          gallery: payload,
          loading: false,
        };
      case PROFILE_ERROR:
        return {
          ...state,
          errors: payload,
          loading: false,
        };
  
      default:
        return state;
    }
  }