import { GET_SOCIALDETAILS, PROFILE_ERROR } from "../actions/types";

const initialState = {
    socialDetails: [],
    loading: true,
    errors: {},
  };

  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_SOCIALDETAILS:
        return {
          ...state,
          socialDetails: payload,
          loading: false,
        };
      case PROFILE_ERROR:
        return {
          ...state,
          errors: payload,
          loading: false,
        };
  
      default:
        return state;
    }
  }