import { combineReducers } from "redux";
import auth from "./auth";
import profile from "./profile";
import socialDetails from "./socialDetails";
import gallery from "./gallery";
import alert from "./alert";
import cards from "./cards";
export default combineReducers({
  alert,
  auth,
  profile,
  socialDetails,
  gallery,
  cards,
});
