import React,{useState,useEffect} from "react";
import { CartHeader } from "../layout/CartHeader";
import { useNavigate } from "react-router-dom";
// import { useCart } from "cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faChevronRight,
  faChevronLeft,
  
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../layout/Footer";
import {  useCart } from "react-use-cart";
function Cart() {
  const navigate = useNavigate();
  const {
   
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
  } = useCart();
  console.log(items);
  const [frontCard, setFrontCard] = useState(null)
  const [backCard, setBackCard] = useState(null)
const [frontDisplay, setFrontDisplay] = useState(true)
  useEffect(() => {
    // Fetch card data from localStorage
    const frontCardData = localStorage.getItem("frontCard");
    setFrontCard(frontCardData)
    const backCardData = localStorage.getItem("backCard");
setBackCard(backCardData)
    // Render front card and back card images if data exists
   
  }, []);
  const checkOut = () => {
    navigate("/vyldfyrecard/checkout");
  };
  console.log(items);
 const handleToggle =()=>{
setFrontDisplay(!frontDisplay)
 }
  // if (isEmpty) return <p>Your cart is empty</p>;
  return (
    <>
      <CartHeader cartTotalItems={totalUniqueItems} />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <div>
              <h6 className="yellowtext mb-4"> your cart </h6>
            </div>
            <h4>Cart ({totalUniqueItems})</h4>
            <div>
              <h6 className="mb-3">Products </h6>
              <div id="products" className="products">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="ms-5 justify-content-center">
                        {" "}
                        Product Name
                      </th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">sub total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, id) => (
                      <tr key={id}>
                        <th scope="row">
                          <span className="me-5">
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => removeItem(item.id)}
                            />
                          </span>
                          <span className="me-2">
                            <FontAwesomeIcon icon={faChevronLeft} onClick={handleToggle} />
                          </span>
                         { frontDisplay ?(<img
                            className="cartimage me-5 ms-5"
                            src={item.cardFrontImage}
                          />):(<img
                            className="cartimage me-5 ms-5"
                            src={item.cardBackImage}
                          />)
                          }
                          <span className="me-5">
                            <FontAwesomeIcon icon={faChevronRight} onClick={handleToggle} />
                          </span>

                          {item.card_name}
                        </th>
                        <td>
                          ${parseInt(item.card_price)} USD
                        </td>

                        <td>
                          <button
                            className="btn btn-secondary me-2"
                            onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
                          >
                            {" "}
                            -{" "}
                          </button>
                          {item.quantity}
                          <button
                            className="btn btn-secondary ms-2"
                            onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                          >
                            {" "}
                            +{" "}
                          </button>
                        </td>
                         <td>
                          ${parseInt(item.card_price)* item.quantity} USD
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <h1>Cart ({totalUniqueItems})</h1>
                </table>
              </div>
              <div className="row">
              <div className="col-md-4"></div>
               
                <div className="col-md-8">
                  <button className="px-5 p-2 mt-3 ms-5 float-end updatebtn text-light">
                    Update Cart
                  </button>
                </div>
              </div>

              <div className="float-end d-flex justify-content-end p-4">
                <div style={{ flex: 1, padding: "20px", borderRadius: "5px" }}>
                  <h3 className="float-end ">Cart Total</h3>

                  <hr />
                 
                  
                  
                  
                  <p>
                    <span style={{ marginRight: "10px" }}>Total Amount:</span>
                    $ {cartTotal}
                  </p>

                  <hr />
                  <button
                    className=" updatebtn px-3 p-2 mt-3 float-end text-light"
                    onClick={checkOut}
                  >
                    Proceed To CheckOut
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </>
  );
}

export default Cart;
