import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import images from "../../utils/imageImports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faQrcode,
  faFillDrip,
} from "@fortawesome/free-solid-svg-icons";
import { faKeyboard } from "@fortawesome/free-regular-svg-icons";
import convertImageToDataURL from "../../utils/SrcimgToDataimg";
import { AXIOS_BASE_URL } from "../../actions/types";
import { TwitterPicker } from "react-color";
import {
  
  getLeftPosition,
  getTopPosition,
} from "../../utils/handlecapture";

const MyCanvas = ({ background, card, flag }) => {
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [color, setColor] = useState("#FFFFFF");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [backgroundColor, setBackgroundColor] = useState(background);
  const [canvasWidth, setCanvasWidth] = useState(640);
  const [canvasHeight, setCanvasHeight] = useState(365);
  const [text, setText] = useState("Type Here ...");
  const [canvas, setCanvas] = useState(null);
  const [textObject, setTextObject] = useState(null);
  const [imageObject, setImageObject] = useState(null);
  const [image, setImage] = useState("");
  // const [isCapturingImage, setISCapturingImage] = useState(false);
  const [dataImg, setDataImg] = useState(null);
  const [imgWidth, setImgWidth] = useState(null);
  // const [imgHeight, setImgHeight] = useState(null);


  // const [frontCard, setFrontCard] = useState(null);
  // const [backCard, setBackCard] = useState(null);

  useEffect(() => {
    setSelectedCard(card);
  }, [card]);

  useEffect(() => {
    const newCanvas = new fabric.Canvas("c", {
      backgroundColor: backgroundColor,
      selection: true, // Enable selection
    });
    fabric.Object.prototype.transparentCorners = false;
    // let radius = 200;
    newCanvas.preserveObjectStacking = true;
  
    setCanvas(newCanvas);
    newCanvas.setWidth(canvasWidth);
    newCanvas.setHeight(canvasHeight);

    if (newCanvas) {
      const containerWidth = newCanvas.offsetWidth;
      const containerHeight = newCanvas.offsetHeight;
      const leftOffset = (containerWidth - canvasWidth) / 2;
      const topOffset = (containerHeight - canvasHeight) / 2;

     
    
      newCanvas.set({ left: leftOffset, top: topOffset });
    }   else {
        console.error("Canvas object is null or undefined.");
      }
    

    const gap = 30; // Gap size in pixels

    for (let i = gap; i < canvasWidth - gap; i += 6) {
      const topLine = new fabric.Line([i, gap, i + 2, gap], {
        stroke: "white",
      });
      const bottomLine = new fabric.Line(
        [i, canvasHeight - gap, i + 2, canvasHeight - gap],
        { stroke: "white" }
      );
      newCanvas.add(topLine, bottomLine);
    }

    for (let i = gap; i < canvasHeight - gap; i += 6) {
      const leftLine = new fabric.Line([gap, i, gap, i + 2], {
        stroke: "white",
      });
      const rightLine = new fabric.Line(
        [canvasWidth - gap, i, canvasWidth - gap, i + 2],
        { stroke: "white" }
      );
      newCanvas.add(leftLine, rightLine);
    }

   card.background_image && convertImageToDataURL(`${AXIOS_BASE_URL}uploads/${card.background_image}`)
      .then((dataUrl) => {
        setDataImg(dataUrl);
      })
      .catch((error) => {
        console.error("Error converting image to data URL:", error);
      });
      // const savedObject = JSON.parse(localStorage.getItem('textbox'));
      // // if (savedObject) {
      // //   console.log("savedObject from localStorage:", savedObject);
      // //   setTextObject(savedObject);
      // // }
      const savedObject = JSON.parse(localStorage.getItem('textbox'));
      console.log(savedObject);
      const defaultTextObject = {
        text: text,
        left: 150,
        top: 50,
        width: 150,
        fontSize: 22,
        fill: color
      };
      if(flag==="front"){
      const newTextObject = new fabric.Textbox(
        savedObject ? savedObject.text : defaultTextObject.text,
        {
          left: savedObject ? savedObject.left : defaultTextObject.left,
          top: savedObject ? savedObject.top : defaultTextObject.top,
          width: savedObject ? savedObject.width : defaultTextObject.width,
          fontSize: savedObject ? savedObject.fontSize : defaultTextObject.fontSize,
          fill: savedObject ? savedObject.fill : defaultTextObject.fill,
        }
      );   

   
    newCanvas.add(newTextObject);
    newCanvas.moveTo(newTextObject, 10);
    setTextObject(newTextObject);
    }

if( flag=== "front"){
  if (typeof image === "string") {
    const imgUrl = image;
    fabric.Image.fromURL(imgUrl, function (img) {
      img.scaleToWidth(imgWidth?imgWidth:400).set({
        left: 100,
        top: 60,
        angle: 0,
        selectable: true,
      });
      newCanvas.add(img);
      setImageObject(img);
    });
  } else if (image instanceof File) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const dataUrl = event.target.result;
      fabric.Image.fromURL(dataUrl, function (img) {
        img.scaleToWidth(imgWidth?imgWidth:400).set({
          left: 100,
          top:  50,
          angle: 0,
          selectable: true,
        });
        newCanvas.add(img);
        setImageObject(img);
      });
    };
    reader.readAsDataURL(image);
  }
}
return () => {
  if (newCanvas) {
    newCanvas.dispose();
  }
};
   
  }, [
    backgroundColor,
    canvasWidth,
    canvasHeight,
    image,
    flag,
    card,
   
  ]);

  useEffect(() => {
    // Load image object from localStorage
    const savedImageObject = JSON.parse(localStorage.getItem('imageObject'));
    if (savedImageObject && canvas && flag==="front") {
      fabric.Image.fromURL(savedImageObject.src, function(img) {
        img.set({
          left: savedImageObject.left,
          top: savedImageObject.top,
          angle: savedImageObject.angle,
          scaleX: savedImageObject.scaleX,
          scaleY: savedImageObject.scaleY,
          selectable: true,
          visible: savedImageObject.visible,
        });
        canvas.add(img);
        setImageObject(img);
      });
    }
  }, [canvas, flag]);
  
  useEffect(() => {
    // Update localStorage when text object changes
  if(canvas){
    
    const handleTextChange = () => {
      if (textObject) {
        const newText = textObject.text;
        setText(newText);
        try {
          localStorage.setItem('textbox', JSON.stringify(textObject));
          console.log("Text saved to localStorage:", newText);
        } catch (error) {
          console.error("Error saving text to localStorage:", error);
        }
      }
    };
  
    if (textObject && typeof textObject.on === 'function' && typeof textObject.off === 'function') {
      textObject.on("modified", handleTextChange);
  
      return () => {
        textObject.off("modified", handleTextChange);
      };
    }
  }
  }, [textObject, canvas]);

useEffect(() => {
  // Update localStorage when image object changes
  if (canvas) {
    console.log(imageObject)
    const handleImageModification = () => {
      console.log(imageObject)
      if(imageObject){
        try {
          localStorage.setItem('imageObject', JSON.stringify(imageObject));
          console.log("Image object saved to localStorage");
        } catch (error) {
          console.error("Error saving image object to localStorage:", error);
        }

      }

    };

    if (imageObject && typeof imageObject.on === 'function' && typeof imageObject.off === 'function') {
     console.log("changed");
      imageObject.on("modified", handleImageModification);
  
      return () => {
        imageObject.off("modified", handleImageModification);
      };
    }
  }
}, [imageObject, canvas]);
  

  // const handleTextChange = () => {
  //   if (textObject) {
  //     const newText = textObject.text;
  //     setText(newText);
  //     try {
  //       localStorage.setItem('textbox', JSON.stringify( textObject));
  //       console.log("Text saved to localStorage:", newText);
  //     } catch (error) {
  //       console.error("Error saving text to localStorage:", error);
  //     }
  //   }
  // };

  // const toggleImageVisibility = () => {
  //   if (imageObject) {
  //     imageObject.set("visible", !imageObject.visible);
  //     canvas.requestRenderAll();
  //   }
  // };

  const handleColorChange = (updatedColor) => {
    setColor(updatedColor.hex);
    if (textObject) {
      textObject.set("fill", updatedColor.hex);
      canvas.renderAll();
    }
  };

  const addOrUpdateTextbox = () => {
    if (textObject) {
      textObject.visible = !textObject.visible;
      canvas.requestRenderAll();
    } else {
      const newTextObject = new fabric.Textbox(text, {
        left: 300,
        top: 50,
        width: 150,
        fontSize: 20,
        fill: color,
      });
      canvas.add(newTextObject);
      setTextObject(newTextObject);
    }
  };

  return (
    <>
      <div className="col-md-2">
        <input
          type="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={(e) => {
            const file = e.target.files[0];
            setImage(file);
          }}
        />
        <ul className="navbar-nav justify-content-center p-1 mt-5">
          <li className="p-3">
            <FontAwesomeIcon
              size="2x"
              onClick={addOrUpdateTextbox}
              icon={faKeyboard}
            />
            <h6> TEXT</h6>
          </li>
          <li className="p-3">
            <label htmlFor="file-upload" className="text-light">
              <FontAwesomeIcon size="2x" icon={faImage} />
              <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={(e) => {
                  const file = e.target.files[0];
                  setImage(file);
                  // toggleImageVisibility();
                }}
              />
            </label>
            <h6> IMAGE </h6>
          </li>
          <li className="p-3">
            <FontAwesomeIcon size="2x" icon={faQrcode} />
            <h6> QR-CODE</h6>
          </li>
          <li className="p-3">
            <FontAwesomeIcon
              size="2x"
              icon={faFillDrip}
              onClick={() => {
                setShowColorPicker(!showColorPicker);
              }}
            />
            <h6> COLOR </h6>
          </li>
        </ul>
        {showColorPicker && (
          <TwitterPicker
            color={color}
            className="color-selector position-absolute"
            name={color}
            onChange={handleColorChange}
          />
        )}
      </div>
      <div className="col-md-8" style={{ position: "relative" }}>
        <div
          ref={containerRef}
          className="canvas-container d-flex align-content-center justify-content-center mt-5"
        >
          <canvas id="c"></canvas>

          {flag === "front" && card  && (
            <div>
              {dataImg && (
                <img
                  src={dataImg}
                  className="canvas-image"
                  alt="staticImage"
                  draggable="false"
                  style={{ pointerEvents: "none" }}
                />
              )}
            </div>
          )}
          {flag === "back" && (
            <img
              src={images.powered}
              className="d-flex flex-column align-items-center justify-content-center z-index-1 position-absolute"
              style={{ top: "87%", width: "31%" }}
            />
          )}
          {flag === "front" && card && (
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top: getTopPosition(card.nfc_icon_placement),
                right:
                  ["1", "3"].includes(card.nfc_icon_placement) &&
                  getLeftPosition(card.nfc_icon_placement),
                left:
                  ["2", "4"].includes(card.nfc_icon_placement) &&
                  getLeftPosition(card.nfc_icon_placement),
                display: "inline-flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <div style={{ position: "relative", minWidth: 0, flex: 1 }}>
                <input
                  className="form-control-plaintext"
                  value={selectedCard.placeholder_text}
                  name="placeholder_text"
                  onChange={(e) => {
                    setSelectedCard({
                      ...selectedCard,
                      placeholder_text: e.target.value,
                    });
                  }}
                  dir="rtl"
                  ref={inputRef}
                />
                <div className="nfc-icon-placement">
                  <img src={images.nfcsymbol} style={{ width: "33px" }} />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="vertical-rule">
          <p>5.88 CM</p>
        </div>
        <div className="horizontal-rule">
          <p>9.02 CM</p>
        </div>
      </div>
    </>
  );
};

export default MyCanvas;
