import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { deleteSocialDetails } from "../../actions/profile";
import Sidebar from "../layout/sidebar";
import Mobile from "./Mobile";
import Footer from "../layout/Footer";
import { Button } from "react-bootstrap";
import axios from "axios";
import { SocialDetailsContext } from "../../utils/providers/RootProvider";
import { saveSocialLinks, updateSocialLinks, updateSocialLinkSwap } from "../../actions/profile";
import { BASE_URL } from "../../actions/types";
import { setAlert } from "../../actions/setAlert";
function SocialDetails({
  auth: { user },
  setAlert,
  deleteSocialDetails,
  saveSocialLinks,
  updateSocialLinks,
  updateSocialLinkSwap
}) {
  const [val, setVal] = useState([
    { socialmedia_name: "", socialmedia_link: "", card_index: "" },
  ]);
  const [changedVal, setChangedVal] = useState("");
  const [url, setUrl] = useState(user.username && user ? user.username : user.googleId);
  const draggedCard = useRef("");
  const draggedOverCard = useRef("");
  const { setData: setSocialDetailsData } = useContext(SocialDetailsContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("users/socialDetails");
        setVal(res.data.socialDetails);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setSocialDetailsData(val);
  }, [val]);

  function handleChange1(e, i) {
    e.preventDefault();
    const socialdetails = [...val];
    socialdetails[i][e.target.name] = e.target.value;
    socialdetails[i].card_index = i+1;
    setChangedVal(socialdetails[i]);
    setVal(socialdetails);

    if (socialdetails[i].id) {
      updateSocialLinks(socialdetails[i]);
    } else {
      saveSocialLinks([socialdetails[i]]);
    }
  }

  const handleSort = async () => {
    // Make a copy of the original array
    const cardClone = [...val];
    
    // Store the card to be swapped
    const temp = cardClone[draggedCard.current];
    
    // Swap the cards
    cardClone[draggedCard.current] = cardClone[draggedOverCard.current];
    cardClone[draggedOverCard.current] = temp;

    // Update card_index for the swapped cards
    cardClone[draggedCard.current].card_index = draggedCard.current + 1;
    cardClone[draggedOverCard.current].card_index = draggedOverCard.current + 1;
    
    // Output the swapped data
    console.log("swapdata", cardClone);

    // Assuming `updateSocialLinkSwap` is a function that updates the social links
    await updateSocialLinkSwap(cardClone);
    
    // Update the state with the modified array
    setVal(cardClone);
}

  const handleAdd = async () => {
    const social = {
      socialmedia_name: "",
      socialmedia_link: "",
      card_index: val.length + 1,
    };

    try {
      await saveSocialLinks([social]);

      const res = await axios.get("users/socialDetails");
      setVal(res.data.socialDetails);
    } catch (error) {
      console.error("Error saving new social detail or fetching data:", error);
    }
  };

  const handleDelete = (i, data) => {
    if (data.id) {
      deleteSocialDetails(data.id);
    }
    const delval = [...val];
    delval.splice(i, 1);
    setVal(delval);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(BASE_URL + "vyldfyre/profile/" + url);
  };

  // const handleUpdate = async () => {
  //   if (changedVal.id) {
  //     await updateSocialLinks(changedVal);
  //   } else {
  //     await saveSocialLinks([changedVal]);
  //   }
  // };

  const sortedVal = val.slice().sort((a, b) => a.card_index - b.card_index);

  return (
    <div id="profile">
      <div className="container">
        <div className="row pt-3">
          <div className="col-md-3 mb-4">
            <Sidebar />
          </div>
          <div className="col-md-6 pt-2 midcontainer">
            <div className="input-group">
              <p className="pb-2">Your Profile is live: </p>
              <div className="input-container">
                <input
                  type="text"
                  style={{
                    color: "white",
                  }}
                  defaultValue={url}
                />
                <label className="filled">vyldfyre/profile/</label>
              </div>
              <button
                onClick={handleCopy}
                className="input-group-text2 browse btn"
                variant="outline-secondary"
                id="button-addon2"
              >
                copy url
              </button>
            </div>
            <div className=" mt-4">
              <h4 className="panel-title">Social Profiles</h4>
              {sortedVal.map((data, i) => {
                return (
                  <div key={i.toString()} className="innrmediabox p-2 mt-3" draggable
                    onDragStart={() => (draggedCard.current = i)}
                    onDragEnter={() => (draggedOverCard.current = i)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className="row">
                      <Form>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                          <Col md="12">
                            <Form.Select
                              className="from-control-plaintext"
                              type="text"
                              style={{ color: "white" }}
                              value={data.socialmedia_name}
                              name="socialmedia_name"
                              onChange={(e) => {
                                handleChange1(e, i);
                              }}
                            >
                              <option>Select Social Channel </option>
                              <option className="text-white" value="facebook">facebook</option>
                              <option value="instagram">instagram</option>
                              <option value="youtube">youtube</option>
                              <option value="linkedin">linkedin</option>
                              <option value="twitter">twitter</option>
                            </Form.Select>
                          </Col>

                          <div className="col-md-11 col-11">
                            <Form.Control
                              plaintext
                              name="socialmedia_link"
                              value={data.socialmedia_link}
                              onChange={(e) => {
                                handleChange1(e, i);
                              }}
                              placeholder="insert link"
                            />
                          </div>
                          <div className="col-md-1 col-1 p-1">
                            <div onClick={() => handleDelete(i, data)}>
                              <FontAwesomeIcon icon={faTrashCan} />
                            </div>
                          </div>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                );
              })}
              <div className="mt-3">
                <Link to="#" className="linkyellow" onClick={handleAdd}>
                  + add social media link
                </Link>
              </div>
             
            </div>
          </div>
          <Mobile />
        </div>
        <Footer />
      </div>
    </div>
  );
}

SocialDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  deleteSocialDetails: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  saveSocialLinks: PropTypes.func.isRequired,
  updateSocialLinks: PropTypes.func.isRequired,
  updateSocialLinkSWap: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deleteSocialDetails,
  setAlert,
  saveSocialLinks,
  updateSocialLinks,
  updateSocialLinkSwap,
})(SocialDetails);
