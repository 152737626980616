import React , {useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import images from './imageImports';

function ItemsWithHover({  iconPath }) {
    const [isHovered, setHovered] = useState(false);

    const containerStyle = {
      position: 'relative',
      display: 'inline-block',
      marginRight: '10px',
    };
  
    const imageStyle = {
      width: '33px',
      height: 'auto',
      display: 'block',
      transition: 'opacity 0.3s ease-in-out',
      opacity: isHovered ? 0 : 1,
    };
  
    const hoverImageStyle = {
      width: '100%',
      height: 'auto',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: isHovered ? 1 : 0,
      transition: 'opacity 0.3s ease-in-out',
    };
  
    const iconStyle = {
      position: 'absolute',
      top: '50%',
      left: '40%',
      transform: 'translate(-50%, -50%)',
      marginLeft: '5px',
      fontSize: '1.5em',
    };
  
  return (
    <div
      style={containerStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img src={images.backImage} alt="image" style={imageStyle} />
      <img src={images.hoverBackImage} alt="image" style={hoverImageStyle} />
      <FontAwesomeIcon className="linkicon" icon={iconPath} style={iconStyle} />
    </div>
  )
}

export default ItemsWithHover