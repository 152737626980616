export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const USERNAME_UPDATE = "USERNAME_UPDATE";
export const UPDATE_FAILURE = "USERNAME_FAILURE";
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const GET_WEBSITE = "GET_WEBSITE";
export const UPDATE_WEBSITE = "UPDATE_WEBSITE";
export const WEBSITE_ERROR = "WEBSITE_ERROR";
export const GET_SOCIALDETAILS = "GET_SOCIALDETAILS";
export const GET_GALLERY = "GET_GALLERY";
export const GET_CARDS = "GET_CARDS";
export const GET_CARDS_ERROR = "GET_CARDS_ERROR";
export const GET_CARD = "GET_CARD";
export const AXIOS_BASE_URL = "https://api.vyldfyre.com/";
// export const BASE_URL = "https://accounts.vyldfyre.com/";
export const BASE_URL = "https://app.vyldfyre.com/";
// export const AXIOS_BASE_URL = "http://localhost:8005/";
// export const BASE_URL = "http://localhost:3000/";
