import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import images from "./imageImports";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { Link } from "react-router-dom";
import { BASE_URL } from "../actions/types";
// import { AXIOS_BASE_URL } from "../actions/types";
function ShareSocial({ details }) {
  const url = "https://accounts.vyldfyre.com/vyldfyre/profile/" + details.url;
  // useEffect(() => {
  //   const updateMetaTags = () => {
  //     // Set Open Graph meta tags dynamically
  //     const ogUrl = BASE_URL + "vyldfyre/profile/" + details.url;
  //     const ogImage = AXIOS_BASE_URL+"uploads/"+details.cover; // Replace IMAGE_URL with the URL of the image you want to display

  //     // Update meta tags
  //     const helmet = document.querySelector('head');
  //     const ogUrlMeta = helmet?.querySelector('meta[property="og:url"]');
  //     const ogImageMeta = helmet?.querySelector('meta[property="og:image"]');

  //     if (ogUrlMeta && ogImageMeta) {
  //       ogUrlMeta.setAttribute('content', ogUrl);
  //       ogImageMeta.setAttribute('content', ogImage);
  //     }
  //   };

  //   updateMetaTags();
  // }, [details]);
  // console.log(url);
  return (
    <div className="socialsharecard mt-3">
          <Helmet>
            <meta property="og:url" content= {url} />
            <meta property="og:title" content= "VYLDFYRE" />
            <meta property="og:description" content="Your Page Description" />
            {/* <meta property="og:image" content={`${AXIOS_BASE_URL}uploads/${details.cover}`} /> */}
            {/* <meta property="og:image" content={`https://api.vyldfyre.com/uploads/${details.cover}`} /> */}
            <meta property="og:image" content="./logonew.png" />
          </Helmet>
      <FacebookShareButton
        url={url}
        quote="Check out this awesome website!"
        appId="721783403402627"
        media={images.mobileLogo}
        className="w-100"
      >
        <div
          className="social-media-card mt-1 mb-1 "
          style={{ marginTop: "-20px" }}
        >
          <Link style={{ marginLeft: "10px", display: "inline-block", width:"100%" }}>            
            <div className="social-media-item">
            <div className="row">
            <div className="col-1"><FacebookIcon  size={30} /></div>
            <div className="col-11 text-center">
            <p className="social-media-name">Share on facebook</p>
            </div>
            </div>
            </div>
          </Link>
        </div>
      </FacebookShareButton>

      <LinkedinShareButton
        url={"https://accounts.vyldfyre.com/vyldfyre/profile/" + details.url}
        className="w-100"
      >
        <div
          className="social-media-card mt-1 mb-1 "
          style={{ marginTop: "-20px" }}
        >
          
          <Link style={{ marginLeft: "10px", display: "inline-block", width:"100%" }}>            
            <div className="social-media-item">
            <div className="row">
            <div className="col-1"><LinkedinIcon size={30} /></div>
            <div className="col-11 text-center">
            <p className="social-media-name">Share on LinkedIn</p>
            </div>
            </div>
            </div>
          </Link>

        </div>
      </LinkedinShareButton>

      <TwitterShareButton url={BASE_URL + "vyldfyre/profile/" + details.url} className="w-100">
        <div
          className="social-media-card mt-1 mb-1 "
          style={{ marginTop: "-20px" }}
        >
        <Link style={{ marginLeft: "10px", display: "inline-block", width:"100%" }}>            
            <div className="social-media-item">
            <div className="row">
            <div className="col-1"><TwitterIcon size={30} /></div>
            <div className="col-11 text-center">
            <p className="social-media-name"> Share on Twitter</p>
            </div>
            </div>
            </div>
          </Link>
        </div>
      </TwitterShareButton>

      {/* <InstapaperShareButton url={BASE_URL + "vyldfyre/profile/" + details.url}>
        <div
          className="social-media-card mt-1 mb-1 "
          style={{ marginTop: "-20px" }}
         >
          <Link style={{ marginLeft: "10px" }}>
            <div className="col social-media-item ">
              <Link>
                <InstapaperIcon size={30} />
              </Link>
              <br />
              <p
                className="justify-center social-media-name"
                style={{ display: "inline-block" }}
              >
                Share on Instagram
              </p>
            </div>
          </Link>
        </div>
      </InstapaperShareButton> */}

      <WhatsappShareButton url={BASE_URL + "vyldfyre/profile/" + details.url} className="w-100">
        <div
          className="social-media-card mt-1 mb-1 "
          style={{ marginTop: "-20px" }}
        >
         
          <Link style={{ marginLeft: "10px", display: "inline-block", width:"100%" }}>            
            <div className="social-media-item">
            <div className="row">
            <div className="col-1"> <WhatsappIcon size={30} /></div>
            <div className="col-11 text-center">
            <p className="social-media-name">Share on whatsApp</p>
            </div>
            </div>
            </div>
          </Link>

        </div>
      </WhatsappShareButton>

      <FacebookMessengerShareButton
        url={BASE_URL + "vyldfyre/profile/" + details.url}
        className="w-100"
      >
        <div
          className="social-media-card mt-1 mb-1 "
          style={{ marginTop: "-20px" }}
        >
          

          <Link style={{ marginLeft: "10px", display: "inline-block", width:"100%" }}>            
            <div className="social-media-item">
            <div className="row">
            <div className="col-1"> <FacebookMessengerIcon size={30} /></div>
            <div className="col-11 text-center">
            <p className="social-media-name">Share on messsenger</p>
            </div>
            </div>
            </div>
          </Link>

        </div>
      </FacebookMessengerShareButton>

      <EmailShareButton url={BASE_URL + "vyldfyre/profile/" + details.url} className="w-100">
        <div
          className="social-media-card mt-1 mb-1 "
          style={{ marginTop: "-20px" }}
        >
           <Link style={{ marginLeft: "10px", display: "inline-block", width:"100%" }}>            
            <div className="social-media-item">
            <div className="row">
            <div className="col-1"><EmailIcon size={30} /></div>
            <div className="col-11 text-center">
            <p className="social-media-name">Share on email</p>
            </div>
            </div>
            </div>
          </Link>



        </div>
      </EmailShareButton>
    </div>
  );
}

export default ShareSocial;
