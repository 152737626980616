import React from 'react'
import images from "../../utils/imageImports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
export const CartHeader = ({cartTotalItems}) => {
  return (
    <div>
        <div>
    <div className="container mb-5">
      <div className="row align-items-center">
        <div className="col-md-5 pt-3 my-4 my-md-0">
          <Link href="index.html">
            <img src={images.logo} className="img-fluid" alt="" />
          </Link>
        </div>
        <div id="dashbrdetails" className="col-md-7 pt-3 ">
          <ul className="nav flex-row justify-content-end">
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <FontAwesomeIcon className="shoppingBag fa-2x" icon={faBagShopping} />
                <span className="badge  start-100 translate-middle badge-rounded text-bg-light text-dark mb-3 mt-0 ">{cartTotalItems}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}
