import axios from "axios";
import { GET_PROFILE,PROFILE_ERROR } from "./types";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./setAlert";


export const checkout = (obj)=> async dispatch =>{
    // const config = {
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // }
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const body = obj;
    try {
        const res =  await axios.post(`api/cart/checkout`, body  )
        console.log(res)
        dispatch(setAlert(res.data.message, 'success'))

        dispatch({
            type: GET_PROFILE,
            payload:res.data,
        });
    } catch (err) {
        dispatch({
            type: PROFILE_ERROR,
            payload: err.message,
        });
    }
}