import { GET_CARDS, GET_CARDS_ERROR } from "../actions/types";
const initialState= {
    cards: [],
    loading: true,
    errors: {},
}


export default function cards (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_CARDS:
        return {
          ...state,
          cards: payload,
          loading: false,
        };
      case GET_CARDS_ERROR:
        return {
          ...state,
          errors: payload,
          loading: false,
        };
  
      default:
        return state;
    }
  }
  