import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import images from "./imageImports";
import { AXIOS_BASE_URL } from "../actions/types";
function EmailValidate() {
  const param = useParams();

  const [validUrl, setValidUrl] = useState(true);
  // console.log(id)
  // console.log(token)

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        await axios.put(
          `${AXIOS_BASE_URL}users/${param.id}/verify/${param.token}`
        );
        setValidUrl(true);
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, []);

  return (
    <div>
      {validUrl ? (
        <div className="container">
          <div className="row">
            <div class="col-12 mt-5">
              <div class="loginbox rounded ">
                <Link to="https://vyldfyre.com">
                  <img src={images.logo} className="img-fluid mb-5" alt="" />
                </Link>

                <div className="text-center mb-5">
                  <h3 className="fw-bolder mb-4 text-light mt-5">
                    Your email address has been verified{" "}
                  </h3>
                  <p className="mb-3 text-light px-4">
                    Your account is not active. Please use the link below to
                    login to your account.
                  </p>
                  <Link
                    to="/login"
                    className="btn btn-dark mt-3 shadow mb-5 verifybutton"
                  >
                    Login to your account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-center vh-100  align-items-center">
            <h2 className="text-center text-light">404 PAGE NOT FOUND</h2>
          </div>
        </div>
      )}

      {/* <Link to={{ pathname: '/login' }} className="btn btn-dark mt-3 shadow mb-5">Login to vyldfyre</Link> */}
    </div>
  );
}

export default EmailValidate;
