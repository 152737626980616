
import Modal from "react-bootstrap/Modal";
import React, { useState,  useRef } from "react";
import { connect } from "react-redux";
import MobileView from "../MobileView";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
// // import { faDownload } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsWithHover from "../../../utils/ItemsWithHover";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter,
    faPinterest,
    faSkype,
    faYoutube,
    faWhatsapp,
  } from "@fortawesome/free-brands-svg-icons";
  
  // import axios from "axios";
  import { BASE_URL } from "../../../actions/types";
function MobilePreview({ auth:{ user }, show, onHide }) {
    const items = [
        // Assuming you have 8 items, add their respective image and icon paths
        { iconPath: faFacebook },
        { iconPath: faInstagram },
        { iconPath: faLinkedin },
        { iconPath: faTwitter },
        { iconPath: faWhatsapp },
        { iconPath: faYoutube },
        { iconPath: faSkype },
        { iconPath: faPinterest },
      ];
      
      const PDFRef = useRef();
  //  console.log(props, "props");
      const [url, setUrl] = useState(
        user.username && user ? user.username : user.googleId
      );
      
     
      const handleCopy = () => {
        navigator.clipboard.writeText(
          BASE_URL + "vyldfyre/profile/" + url
        );
      };
    // const downloadPdf = () =>{
    //   const input = PDFRef.current;
    //   html2canvas(input).then((canvas)=>{
    //     const imgData = canvas.toDataURL('image/pdf');
    //     const pdf = new jsPDF('p','mm','a4',true);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = pdf.internal.pageSize.getHeight();
    //     const imgWidth = canvas.width;
    //     const imgHeight = canvas.height;
    //     const ratio = Math.min(pdfWidth/imgWidth, pdfHeight/imgHeight);
    //     const imgX = pdfWidth - imgWidth * ratio;
    //     const imgY = 0;
    //     pdf.addImage(imgData,'PNG',imgX,imgY,imgWidth * ratio, imgHeight * ratio);
    //     pdf.save("vyldfyre.pdf");
    
    //   })  
    // }
     

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: "#000000a8" }}
      >
      <Modal.Header className="justify-content-end text-light"  >
       
        
          <FontAwesomeIcon className='fa-2x' onClick={onHide} icon={faX} />
        
      
      </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div id="preview" className="col-md-6 pt-2" >
               <MobileView />
              </div>
              <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="form-group" >
                  <div id="profile" className="input-group">
                    <p class="mb-2"> Your Profile is live:</p>
                    <div className="input-container ">
                      <input
                        type="text"
                        className="text-white"
                        defaultValue={url}
                      />
                      <label className="filled">
                        vyldfyre/profile/
                      </label>
                    </div>
                    <Button
                      onClick={handleCopy}
                      className="input-group-text3 browse btn"
                      variant="outline-secondary"
                      id="button-addon2"
                    >
                      copy url
                    </Button>
                  </div>
                  {/* <div className="text-center my-5">
                    <Link onClick={downloadPdf} className="linkwhite">
                      Download Pdf {<FontAwesomeIcon icon={faDownload} />}
                    </Link>
                  </div> */}
                   <div className="text-center position-relative">
                    <p class="my-2">Share on</p>

                    <div>
                      {items.map((item, index) => (
                        <ItemsWithHover key={index} {...item} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </Modal.Body> 
      </Modal>
    </>
  )
}

MobilePreview.propTypes={
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({

  auth: state.auth,
 
});
export default connect(mapStateToProps)(MobilePreview);