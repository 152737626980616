import React from 'react'
import PropTypes from 'prop-types'

function miniwebsite(props) {
  return (
    <div>miniwebsite</div>
  )
}

miniwebsite.propTypes = {}

export default miniwebsite
