import React from 'react'
// import PropTypes from 'prop-types'

function analytics(props) {
  return (
    <div>analytics</div>
  )
}

// analytics.propTypes = {}

export default analytics
