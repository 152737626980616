import React, { useState, } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MobileView from "./MobileView";
import MobilePreview from "./popups/MobilePreview";
import { Modal } from "react-bootstrap";

function Mobile() {
  const [previewShow, setPreviewShow] = useState(false);
  const handlePreview =()=>{
      // e.preventDefault();
      
      setPreviewShow(!previewShow);
  }
  
  return (
    <div className="col-md-3 position-relative pt-2">
      <div className="mobilecont mb-5">
        <div className="mb-3">
          
            <Link
              to="#"
              className="btn prevbtn"
              // data-bs-toggle="modal"
              // data-bs-target="#staticBackdrop"
              onClick={(e) => {handlePreview()} }
            >
            Show Preview
            </Link>
          
        </div>
        <MobileView />
       
      </div>

      <MobilePreview
        show={previewShow}
        onHide={() => setPreviewShow(false)}
      />
    </div>
  );
}


const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  profile: state.profile,
  socialDetails: state.socialDetails,
  gallery: state.gallery,
  // loading: state.auth.loading
});

export default connect(mapStateToProps)(Mobile);
