import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Cropper from "react-easy-crop";
import { Link } from "react-router-dom";
import { AXIOS_BASE_URL } from "../../actions/types";
import images from "../../utils/imageImports";
import blobUrlToImageFile from "../../utils/datatofile";
import getCroppedImg from "../../utils/crop";
import { updateCoverPic } from "../../actions/profile";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const CONTAINER_HEIGHT = 300
function CoverPic({
  coverpic,
  auth: { user },
  updateCoverPic,
  sendDataToProfile,
}) {
  console.log(coverpic);
  const [coverSrc, setCoverSrc] = useState("");
  const [coverShow, setCoverShow] = useState(false);
  const [cover, setCover] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, onZoomChange] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("");
  const oldPic = coverpic;

  useEffect(() => {
    sendDataToProfile(cover);
  }, [cover]);

  const onChange = (e) => {
    e.preventDefault();
    console.log(e.target.files[0]);
    const fileArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    console.log(fileArray);
    //blobUrlToImageFile(fileArray[0], 'cover.jpg');
    // console.log(cover.jpg)
    //const c = fileArray;
    setCoverSrc(fileArray);
    // console.log(images)
    Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
  };
  const onCropChange = (crop) => {
    setCrop(crop);
  };
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea);
    //     console.log(croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // updating the coverpic in database //
  const saveChangesCover = async () => {
    console.log(croppedAreaPixels);
    console.log("hello");
    const croppedImageUrl = await getCroppedImg(coverSrc, croppedAreaPixels);
    const imageFile = await blobUrlToImageFile(croppedImageUrl, "cover.jpg");
    // console.log(croppedImageUrl);
    const formData = new FormData();

    formData.append("cover_pic", imageFile);
    try {
      setCover(croppedImageUrl);
      updateCoverPic(formData, user.id);
    } catch (err) {
      console.log("error uploading");
    }

    setCoverShow(false);
  };

  return (
    <div>
      <div className="cambg">
        {cover ? (
          <img src={cover} className="cambg" alt="cover" />
        ) : oldPic ? (
          <img
            src={`${AXIOS_BASE_URL}uploads/${oldPic}`}
            className="cambg"
            alt="cover"
          />
        ) : (
          <img src={images.coverImage} className="cambg" alt="" />
        )}
        <div className="cam">
          <div className="input--file">
            <span
              className="small-round-button float-end"
              style={{ position: "relative" }}
            >
              <Link
                onClick={() => {
                  setCoverShow(true);
                }}
              >
                <img src={images.camara} alt="" />
              </Link>
            </span>
          </div>
        </div>
        <Modal
          show={coverShow}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          onHide={() => {
            setCoverShow(false);
          }}
        >
          <Modal.Header style={{ justifyContent: "space-between", alignItems: "center" }}className='d-flex bg-light' >
            <Modal.Title className="text-dark">cover picture</Modal.Title>
            <Link
              className=" text-dark btn btn-link"
              onClick={() => {
                setCoverShow(false);
              }}
            >
              <FontAwesomeIcon className="float-end" icon={ faXmark}/>
            </Link>
          </Modal.Header>
          <Modal.Body
            style={{ backgroundColor: "white" }}
            className="container"
          >
            <div className="container-fluid">
              <div className="row flex-nowrap">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <h5 className="text-dark text-center py-3">
                    Showcase your personality,intrest,team moments or notable milestimes</h5>
                  <p className="text-grey text-center">
                    A good background photo will help you stand out
                  </p>
                  <div>
                    <input
                      id="imageInput"
                      type="file"
                      className="mb-3"
                      accept=".png, .jpg, .jpeg"
                      onChange={onChange}
                      style={{
                        padding: "10px",

                        display: "none",
                        // Green background color
                        color: "white", // White text color
                        border: "none", // No border
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                    />
                    <label
                      htmlFor="imageInput"
                      className="mb-3 mt-3 col "
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        position: "relative",
                        padding: "10px 15px",
                        cursor: "pointer",
                        border: "none",
                        borderRadius: "2px",
                      }}
                    >
                      Choose Image
                    </label>
                  </div>
                  <div className="cropper-container " style={{ width: "100%" }}>
                    <br />
                    <br />
                    <div>
                      <Cropper
                        image={coverSrc}
                        crop={crop}
                        showGrid={false}
                        zoom={zoom}
                        aspect={3 / 1}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                        onMediaLoaded={(mediaSize) => {
                          // Adapt zoom based on media size to fit max height
                          onZoomChange(CONTAINER_HEIGHT / mediaSize.naturalHeight)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer  className="d-flex flex-wrap align-items-center justify-content-center bg-light " >
            <div className="d-flex ">
              <button
                className="me-2 p-1 modal-button"
                variant="outline-dark"
                onClick={() => {
                  setCoverShow(false);
                }}
              >
                Change Photo
              </button>
              <button className="modal-button-apply p-1" variant="dark" onClick={saveChangesCover}>
                Apply
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

CoverPic.propTypes = {
  auth: PropTypes.object.isRequired,
  updateCoverPic: PropTypes.func.isRequired,
  // profile: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  profile: state.profile,
  // loading: state.auth.loading
});

export default connect(mapStateToProps, { updateCoverPic })(CoverPic);
