import React, { useRef, useEffect, useState } from "react";
import { fabric } from "fabric";
import images from "../utils/imageImports";
// import { handleCapture } from "../utils/handlecapture";
import convertImageToDataURL from "../utils/SrcimgToDataimg";
import { AXIOS_BASE_URL } from "../actions/types";
const FabricEditor2 = ({  card, flag , getCanvasRef}) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState("");
  const [canvasWidth, setCanvasWidth] = useState(640);
  const [canvasHeight, setCanvasHeight] = useState(365);
  const [textBox, setTextBox] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [imageObject, setImageObject] = useState(null);
const [dataImg, setDataImg] = useState(null);

  useEffect(() => {
    setSelectedCard(card);
    // setBackgroundColor(card.backgroundColor)
  }, [card]);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvasNew = new fabric.Canvas(canvasRef.current, {
      backgroundColor: selectedCard.backgroundColor || "#000000",
    });
    canvasNew.setWidth(canvasWidth);
    canvasNew.setHeight(canvasHeight);
    setCanvas(canvasNew);

    if (selectedCard && selectedCard.background_image) {
      convertImageToDataURL(`${AXIOS_BASE_URL}uploads/${selectedCard.background_image}`)
          .then((dataUrl) => {
              setDataImg(dataUrl);
        
              
          })
          .catch((error) => {
              console.error("Error converting image to data URL:", error);
          });
  }
    // Center align canvas
    const containerWidth = canvasRef.current.parentElement.offsetWidth;
    const containerHeight = canvasRef.current.parentElement.offsetHeight;
    const leftOffset = (containerWidth - canvasWidth) / 2;
    const topOffset = (containerHeight - canvasHeight) / 2;
    canvasNew.set({ left: leftOffset, top: topOffset });

    // Load canvas data from local storage
    const textbox = JSON.parse(localStorage.getItem("textbox"));
    setTextBox(textbox);
    const imagebox = JSON.parse(localStorage.getItem("imageObject"));
    setImageObject(imagebox);
    
  }, [canvasRef, canvasWidth, canvasHeight, selectedCard ]);

  useEffect(() => {
    if (!textBox || !canvas) return;

    // Create fabric textbox object
    if(flag==="front"){
      const fabricTextBox = new fabric.Textbox(textBox.text, {
        left: textBox.left,
        top: textBox.top,
        fontFamily: textBox.fontFamily,
        fontSize: textBox.fontSize,
        fill: textBox.fill,
        width: textBox.width,
        height: textBox.height,
        lineHeight: textBox.lineHeight,
        selectable: false,
      });
  
      // Add textbox to canvas
      canvas.add(fabricTextBox);
    }
    
  }, [textBox, canvas]);

  useEffect(() => {
    if (!imageObject || !canvas) return;
  
    // Create fabric image object
    if(flag === "front"){
      fabric.Image.fromURL(imageObject.src, function(img) {
        // Set the properties of the image object
        img.set({
          left: imageObject.left,
          top: imageObject.top,
          angle: imageObject.angle,
          scaleX: imageObject.scaleX,
          scaleY: imageObject.scaleY,
          selectable: false,
          visible: imageObject.visible,
        });
    
        // Add the image object to the canvas
        canvas.add(img);
      });
    }
   
  }, [imageObject, canvas]);
useEffect(()=>{
getCanvasRef(containerRef);
},[containerRef])
 

  return (
    <>
      <div  style={{ position: "relative" }}>
        <div ref={containerRef} className="canvas-container d-flex align-content-center justify-content-center mt-3 ms-5">
          <canvas ref={canvasRef}  className="canvas"></canvas>
          {flag === "front" && card && dataImg && (
            <img
              src={dataImg}
              className="canvas-image2"
              alt="staticImage"
            />
          )}
          {flag === "back" && (
            <img
              src={images.powered}
              className="d-flex flex-column align-items-center justify-content-center z-index-1 position-absolute"
              style={{ top: "87%", width: "31%" , right: "100px"}}
            />
          )}
          {flag === "front" && card && (
            <div
              style={{
                position: "absolute",
                zIndex: 10,
                top:
                  card.nfc_icon_placement === "3" ||
                  card.nfc_icon_placement === "4"
                    ? "calc(100% - 60px)"
                    : "10px",
                    right: ["1", "3"].includes(card.nfc_icon_placement) ? "calc(17%)" : "auto",
                    left: ["2", "4"].includes(card.nfc_icon_placement) ? "65px" : "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ position: "relative", minWidth: 0, flex: 1 }}>
                <input
                  className="form-control-plaintext"
                  defaultValue={selectedCard.placeholder_text}
                  name="placeholder_text"
                  onChange={(e) => {
                    setSelectedCard({
                      ...selectedCard,
                      placeholder_text: e.target.value,
                    });
                  }}
                  dir="rtl"
                />
                <div className="nfc-icon-placement">
                  <img src={images.nfcsymbol} style={{ width: "33px" }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <button onClick={handleContinueClick}>Continue</button> */}
    </>
  );
};

export default FabricEditor2;
