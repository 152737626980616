import React, { useState } from "react";
import { CartHeader } from "../layout/CartHeader";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import Footer from "../layout/Footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkout } from "../../actions/checkout";
import { setAlert } from "../../actions/setAlert";
import {  useCart } from "react-use-cart";


function Checkout({auth:{user},setAlert ,checkout,cart}) {
  const {
  
    cartTotal,
    
    items,
    
  } = useCart();

  const [formData, setFormData] = useState({
    user_id: user.id,
    firstname: "",
    lastname: "",
    companyname: user.company_name,
    country: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    state: "",
    zipcode: "",
    phoneNumber: user.phn_number,
    emailId: user.email,
    checkoutText: "",
    orderedItems: JSON.stringify(items), 
    promo: false,
  });

 console.log(items);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
console.log(formData)

      // Make an HTTP POST request to your backend endpoint to save the data
      checkout(formData)
      // setAlert()
      // console.log('Data saved:', response.data);
    } catch (error) {
      console.error('Error saving data:', error);
    }
    // Add your form submission logic here
    console.log(formData);

  };
  return (
    <div>
      <CartHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <div>
              <h5 className="yellowtext mb-4"> Checkout </h5>
              <hr />
              <div>
                <p>
                  {" "}
                  <FontAwesomeIcon className="yellowtext" icon={faGift} /> Have
                  a Coupon?{" "}
                  <Link className="text-light underline-link">
                    click here to enter your code
                  </Link>
                </p>
              </div>
              <hr />
            </div>
            <div id="profile" className="col-md-6">
              <h6>Billing Details</h6>
              <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label">
                    First Name
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail4"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPassword4" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputPassword4"
                    name="lastname"

                    value={formData.lastname}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label">
                    Company Name (optional)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress"
                    placeholder="1234 Main St"
                    name="companyname"
                    value={formData.companyname}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label">
                    Country/region
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="form-select"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    <option value="india">India</option>
                    <option value="dubai">Dubai</option>
                    <option value="uk">UK</option>
                    <option value="usa">United States</option>
                    <option value="australia">Australia</option>

                    {/* Add more options as needed */}
                  </select>
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress"
                    name="addressLine1"
                    // placeholder="1234 Main St"
                    value={formData.addressLine1}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress2" className="form-label">
                    Address 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress2"
                    name="addressLine2"

                    // placeholder="Apartment, studio, or floor"
                    value={formData.addressLine2}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputCity" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCity"
                    name="town"
                    value={formData.town}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="inputState" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputZip"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  />
                
                </div>
                <div className="col-md-12">
                  <label htmlFor="inputZip" className="form-label">
                    Zip
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputZip"
                    name="zipcode"
                    value={formData.zipcode}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputZip" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputZip"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputZip" className="form-label">
                    Email id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputZip"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      Sign me up to recieve special deals,email updates and news
                      (optioanl)
                    </label>
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div id="profile" className="col-md-5 ms-5">
              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    Ship to differnt Address?
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                  Example textarea
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  name="checkoutText"
                  value={formData.checkoutText}
                  onChange={handleChange}
                ></textarea>
              </div>
           
            <div id="products" className="products">
            <h1>Ordered Items</h1>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="ms-5 justify-content-center">
                        {" "}
                        Product Name
                      </th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">sub total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, id) => (
                      <tr key={id}>
                        <th scope="row">
                          
                         
                          <img
                            className="cartimage me-2 ms-2"
                            src={`http://localhost:8005/uploads/${item.background_image}`}
                          />
                        

                          {item.card_name}
                        </th>
                        <td>
                          ${parseInt(item.card_price)} USD
                        </td>

                        <td>
                         
                          {item.quantity}
                         
                        </td>
                         <td>
                          ${parseInt(item.card_price)* item.quantity} USD
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  
                </table>
                <span className="float-end">
                Cart Total: ${cartTotal}
                </span>
                
              </div>
              </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
Checkout.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  checkout: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,

  // loading: state.auth.loading
});
export default connect(mapStateToProps, { setAlert ,checkout})(Checkout);
