async function blobUrlToImageFile(blobImageUrl, fileName) {
    try {
      // Fetch the Blob data from the URL
      const response = await fetch(blobImageUrl);
      const blobData = await response.blob();
  
      // Create a File from the Blob data
      const file = new File([blobData], fileName, { type: blobData.type });
  
      // Now 'file' contains the data of the original Blob URL
  
      // You can use the 'file' variable as needed, for example:
      console.log(file);
  
      return file;
    } catch (error) {
      console.error("Error converting Blob URL to Image File:", error);
    }
  }
  export default blobUrlToImageFile;
  