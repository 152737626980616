import html2canvas from "html2canvas";
// import images from "./imageImports";
// import base64toFile from "./base64tofile";
export const handleCapture = async (containerRef, flag) => {
    return new Promise((resolve, reject) => {
        const container = containerRef.current;

        // Use html2canvas to capture the container element
        html2canvas(container).then((canvas) => {
            // Resize the captured canvas to the desired dimensions
            const resizedCanvas = document.createElement("canvas");
            const ctx = resizedCanvas.getContext("2d");
            resizedCanvas.width = 640;
            resizedCanvas.height = 320;
            ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

            // Convert the resized canvas to data URL
            const imageData = resizedCanvas.toDataURL("image/png");

            // Determine filename based on flag
            let filename;
            if (flag === "front") {
                filename = "front_card.png";
                console.log("captured front")
                // Store the captured image data for front card in local storage
                localStorage.setItem("frontCard",  imageData);
            } 
             else {
                filename = "canvas_capture.png"; // Default filename
            }

            
            

            resolve({filename, imageData}); 
            // return imageData// Resolve with the filename
        }).catch(error => {
            reject(error); // Reject if any error occurs
        });
    });
};


export function getTopPosition(nfcIconPlacement) {
    switch (nfcIconPlacement) {
        case "1":
        case "2":
            return "82px"; // Adjust as needed
        case "3":
        case "4":
            return "calc(100% - 70px)"; // Adjust as needed
        default:
            return "50%"; // Default to center if placement is not defined
    }
}

export function getLeftPosition(nfcIconPlacement) {
    switch (nfcIconPlacement) {
        case "1":
        case "3":
            return "calc(100% - 78%)"; // Adjust as needed
        case "2":
        case "4":
            return "90px"; // Adjust as needed
        default:
            return "50%"; // Default to center if placement is not defined
    }
}
