import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import images from "../../utils/imageImports";
import { Link, Navigate } from "react-router-dom";
import Footer from "../layout/Footer";
import PasswordVisibilityToggler from "../../utils/passwordVisibilityToggler";
import LoginValidator from "../../utils/loginValidator";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { googlelogin } from "../../actions/auth";
import { setAlert } from "../../actions/setAlert";

function Login({ login, isAuthenticated, auth:{user}, googlelogin, setAlert }) {
  const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get('from');
  // for password visibility toggler//
  const [passwordInputType, toggleIcon] = PasswordVisibilityToggler();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [user1, setUser1] = useState("");

  var [errors, setErrors] = useState();
  // checking for errors for the given input values //

  useEffect(() => {
    setErrors(LoginValidator(values));
  }, [values]);

  // assigning the values to their respective fields //
  function handleChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
    LoginValidator(values);
  }
  // submitting the form and invoking the api //
  const onSubmit = async (e) => {
    e.preventDefault();

    //console.log(errors);
    if (errors === "") {
      try {
        login(values);
        // console.log(user)
        
      } catch (e) {
        console.log(e);
      }
    } else {
      // setErrors(LoginValidator(values));
      setAlert(errors, "danger");
    }
  };

  // functionality for login with google //

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser1(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  //console.log(user);
  useEffect(() => {
    if (user1) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user1.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user1.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const email = res.data.email;
          const googleId = res.data.id;
          const is_verified = res.data.verified_email;
          // const lastName = res.data.family_name;
          // const firstName = res.data.given_name;
          const name = res.data.name;
          // const picture = res.data.picture;
          googlelogin({ email, googleId, is_verified, name });
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }, [user1, googlelogin]);

  console.log(from);

  if (isAuthenticated) {
    if(from === "cart"){
      return <Navigate to="/vyldfyrecard/cart" />
    }
    else{
      return <Navigate to="/profile" />;
    }
    
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="d-none d-sm-block">
            <div className="row pt-4">
              <div className="col-md-6">
                <Link to="https://vyldfyre.com">
                  <img
                    src={images.logo}
                    className="img-fluid"
                    width="208"
                    alt=""
                  />
                </Link>
              </div>
              <div className="col-md-6 text-end">
                <Link to="/register" className="signbtn">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- For mobile resposive -->  */}
          <div className="d-md-none">
            <div className="row">
              <div className="col-md-12 pt-3">
                <Link href="index.html">
                  <img
                    src={images.logo}
                    to="/"
                    width="153"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
                <Link to="/login" className="signbtn">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- end mobile resposive -->       */}
        </div>
      </header>
      <section id="main">
        <div id="signin" className="container position-relative">
          <div className="row">
            <div className="col-md-4 mt-3">
              <form className="mt-5">
                <div className="welcard p-4 mt-5">
                  <p className="mb-5 mt-2">
                    <img src={images.loginIcon} alt="" />
                  </p>
                  <h6>Welcome to VYLDFYRE</h6>
                  <p>Please sign in or sign up below.</p>
                  <div className="text-start mt-4">
                    <div className="mb-4">
                      {/* <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        required
                      /> */}
                      <div className="form-item mb-3">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="email">Email</label>
                      </div>
                    </div>
                    <div className="form-item mb-3">
                      {/* <input
                      type={passwordInputType}
                      className="form-control"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      required
                    /> */}
                      <input
                        type={passwordInputType}
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        name="password"
                        required
                      />
                      <label htmlFor="password">Password</label>
                      <span
                        className="passwordToggle"
                        style={{ color: "white" }}
                      >
                        {toggleIcon}
                      </span>
                    </div>
                    <button
                      onClick={onSubmit}
                      // style={{ backgroundColor: "white" }}
                      type="submit"
                      className="loginbtn my-4 border-0"
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="googlecard p-4">
                  <Link
                    className="googlebtn my-2"
                    onClick={() => googleLogin()}
                  >
                    <img src={images.googleIcon} className="pe-2" alt="" /> Log
                    in with Google
                  </Link>
                </div>
                <p className="text-center pt-4 mb-5">
                  Don't have an account?{" "}
                  <Link to="/register" className="linkyellow1">
                    Sign up
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-7 dispnone">
              <img src={images.innerBg} className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </>
  );
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  googlelogin: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, { login, googlelogin, setAlert })(
  Login
);
