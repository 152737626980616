function base64toFile(base64String, fileName, fileType) {
    // Split the base64 string into data and mime
    const data = base64String.split(",")[1];
    const mime = base64String.split(";")[0].slice(5);
  
    // Convert the base64 string to a Uint8Array
    const bytes = new Uint8Array(
      atob(data)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
  
    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: mime });
  
    // Create a File from the Blob
    const file = new File([blob], fileName, { type: mime });
  
    return file;
  }
  
  export default base64toFile;
  