import "./styles/login.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/cards.css"
import React, { useEffect } from "react";
import { loaduser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import Login from "./components/authentication/Login";
import SignUp from "./components/authentication/SignUp";
import { Provider } from "react-redux";
import store from "./store.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Alert from "./components/layout/Alert";
import AuthenticatedRoutes from "./routes/authenticatedRoutes";
import PublicProfile from "./components/public/PublicProfile";
import EmailValidate from "./utils/EmailValidate";
import InitialDataContextSetter from "./utils/contexts/InitialDataContextSetter";
import VyldCard from "./components/shop/VyldCard";
import CardFrontPageEdit from "./components/shop/CardFrontPageEdit";
import CardBackEdit from "./components/shop/CardBackEdit";
import CardWithImage from "./components/shop/CardWithImage";
import ConfirmPage from "./components/shop/ConfirmPage";
import { CartProvider} from "react-use-cart";
import images from "./utils/imageImports.js";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
if (localStorage.token) {
  //console.log(localStorage.token)
  setAuthToken(localStorage.token);
}
function App() {
  useEffect(() => {
    //console.log(store.dispatch(loaduser()));
    store.dispatch(loaduser());
  }, []);
  return (
    <>
      <InitialDataContextSetter>
        <Provider store={store}>
        <CartProvider>
          <Router>
            <Alert />
            <Routes>
              {/* <Route index path="/" element={<HomePage />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/vyldfyre-card" element={<VyldCard />} />
              <Route path="/vyldfyrefrontcard/:color/:id" element={<CardFrontPageEdit />} />
              {/* <Route path="/vyldfyrebackcard" element={<CardBackEdit />} /> */}
              <Route path="/vyldfyreimagecard" element={<CardWithImage />} />
              <Route path="/confirmcard" element={<ConfirmPage />} />

              <Route path="/vyldfyre/profile/:username" element={<PublicProfile />} />
              <Route
                path="/users/:id/verify/:token"
                element={<EmailValidate />}
              />

              <Route exact path="/*" element={<AuthenticatedRoutes />} />
            </Routes>
          </Router>
          </CartProvider>
        </Provider>
      </InitialDataContextSetter>
     
    </>
  );
}

export default App;
