import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faQrcode,
  faFillDrip,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { faKeyboard, faEye } from "@fortawesome/free-regular-svg-icons";
function CardEditLayout({card, background}) {
  console.log(card);
  console.log(background);
  const handleNextClick = () => {
    // Assuming you have logic to determine selected card and background
    const selectedCard = card; // Example: card.id
    const selectedBackground = background; // Example: background.url
    
    // Saving data to localStorage
    localStorage.setItem('selectedCard', JSON.stringify(selectedCard));
    localStorage.setItem('selectedBackground', JSON.stringify(selectedBackground));
  };
  return (
<>
<div className="col-md-12">
              <div className="border-bottom border-light">
                <div className="row align-items-center">
                  <div className="col">
                    <ul className="nav justify-content-start gap-4">
                      <li>
                        <p>project</p>
                      </li>
                      <li>
                        <span>VYLDFYRE branded Card</span>
                      </li>
                      <li>
                        <p className="text-success">saved</p>
                      </li>
                    </ul>
                  </div>
                  <div className="col text-end">
                    <ul className="nav justify-content-end gap-4">
                      <li>
                        <span>
                            <Link className='text-light' to="/vyldfyre-card">
                          <FontAwesomeIcon icon={faEdit} />
                          Change Template
                          </Link>
                        </span>
                      </li>
                      <li>
                        <Link className="button btn-secondary px-3" onClick={handleNextClick} to="/confirmcard">
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
           
           
</>
  )
}

export default CardEditLayout