import React from 'react'
import PropTypes from 'prop-types'

function ministore(props) {
  return (
    <div>ministore</div>
  )
}

ministore.propTypes = {}

export default ministore
