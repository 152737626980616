import React from "react";
import ShopHeader from "../layout/ShopHeader";
import Footer from "../layout/Footer";
import CardEditLayout from "../layout/CardEditLayout";
import RightSideBar from "../layout/RightSideBar";
// import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
function CardWithImage() {
   

  return (
    <>
      <ShopHeader />
      <section className="dashboard">
        <div className="container mb-5">
          <div className="row my-5 g-0">
            <CardEditLayout />
            <div className="col-md-8">
       
            </div>
           <RightSideBar />
          </div>
        </div>
      </section>

     

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </>
  );
}

export default CardWithImage;
