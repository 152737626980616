import axios from "axios";
import { GET_CARDS, GET_CARDS_ERROR ,GET_CARD, AXIOS_BASE_URL} from "./types";
import { setAlert } from "./setAlert";
import setAuthToken from "../utils/setAuthToken";

export const getCards =()=> async dispatch =>{

    try {
        const response = await axios.get("api/getcards")
        console.log(response);
        dispatch({
            type: GET_CARDS,
            payload:response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CARDS_ERROR,
            payload:error.data.message,
        });
    }

}

export const getCard =(id)=>async dispatch =>{
    try {
        const response = await axios.get(`api/getcards/${id}`)
        console.log(response);
        dispatch({
            type: GET_CARD,
            payload:response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CARDS_ERROR,
            payload:error.data.message,
        });
    }

}
