import React, { useState ,useEffect} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import images from "../../utils/imageImports";
import { AXIOS_BASE_URL } from "../../actions/types";
import Avatar from "react-avatar-edit";
import base64toFile from "../../utils/base64tofile";
import { updateProfilePic } from "../../actions/profile";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
function ProfilePic({
  profilepic,
  auth: { user },
  updateProfilePic,
  sendDataToProfile,
}) {
  const [profilePic, setProfilePic] = useState("");
  const [src, setsrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [show, setShow] = useState(false);
  const oldPic = profilepic;
  console.log(oldPic);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const onCrop = (view) => {
    if (view) {
      console.log(view);
      setPreview(view);
    }
  };

  const saveChanges = async  () => {
    console.log(preview);
    const previewFile = await base64toFile(preview, "profile.jpg");
    console.log(previewFile);
    const formData = new FormData();
    formData.append("profile_pic", previewFile);
    const imageUrl = URL.createObjectURL(previewFile);
    try {
      setProfilePic(imageUrl);
      updateProfilePic(formData, user.id);
    } catch (err) {
      console.log("error uploading");
    }

    //setBasicDetails({ ...basicDetails, profile: previewFile });
    setShow(false);
  };
  useEffect(()=>{
    sendDataToProfile(profilePic);
  },[profilePic])
  return (
    <div>
      <div className=" roundcam rounded-image-container">
        <span>
          <Link onClick={handleShow}>
            {profilePic ? (
              <img src={profilePic} alt="profile-Pic " />
            ) : oldPic ? (
              <img
                src={`${AXIOS_BASE_URL}uploads/${oldPic}`}
                className="rounded-image"
                alt="preview"
              />
            ) : (
              <img src={images.camara} alt="" />
            )}
          </Link>
        </span>
      </div>
      <Modal
        show={show}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        onHide={handleClose}
      >
        <Modal.Header style={{ backgroundColor: "white",justifyContent: "space-between", alignItems: "center" }}className='d-flex' >
            <Modal.Title className="text-dark">Profile Picture</Modal.Title>
            <Link
              className=" text-dark btn btn-link"
              onClick={() => {
                setShow(false);
              }}
            >
              <FontAwesomeIcon className="float-end" icon={faXmark}/>
            </Link>
          </Modal.Header>
        <Modal.Body className="container bg-light">
          <Avatar
            width={460}
            height={295}
            // onImageLoad={(image)=>setsrc(image)}
            src={src}
            cropRadius={80}
            onCrop={onCrop}
          />
        </Modal.Body>
        <Modal.Footer  className="d-flex flex-wrap align-items-center justify-content-center bg-light " >
            <div className="d-flex ">
              <button
                className="me-2 p-1 modal-button"
                variant="outline-dark"
                onClick={() => {
                  setShow(false);
                }}
              >
                Change Photo
              </button>
              <button className="modal-button-apply p-1" variant="dark" onClick={saveChanges}>
                Apply
              </button>
            </div>
          </Modal.Footer>
      </Modal>
    </div>
  );
}

ProfilePic.propTypes = {
  auth: PropTypes.object.isRequired,
  
  updateProfilePic: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  
  // loading: state.auth.loading
});

export default connect(mapStateToProps, {
  updateProfilePic,
})(ProfilePic);
