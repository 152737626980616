import React,{createContext}  from "react";
import PropTypes from "prop-types";
export const BasicDetailsContext = createContext();
export const SocialDetailsContext = createContext();
export const GalleryContext = createContext();

const RootProvider = ({ children, contextData }) => {

console.log(contextData);
  return(
    <BasicDetailsContext.Provider value={contextData.basicDetails}>
      <SocialDetailsContext.Provider value={contextData.socialDetails}>
        <GalleryContext.Provider value={contextData.gallery}>
          {children}
        </GalleryContext.Provider>
      </SocialDetailsContext.Provider>
    </BasicDetailsContext.Provider>
  );
  }

  RootProvider.propTypes = {
    children: PropTypes.node.isRequired,
    contextData: PropTypes.shape({
      basicDetails: PropTypes.object.isRequired,
      socialDetails: PropTypes.object.isRequired,
      gallery: PropTypes.object.isRequired
    }).isRequired
  };
  export default RootProvider;