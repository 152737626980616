import React from 'react'
import PropTypes from 'prop-types'
// import MyCanvasContainer from '../../shop/MyContainer'
function settings(props) {

  return (
    <>
    <div>settings</div>
    

    
        <div>
            {/* <MyCanvasContainer  /> */}
        </div>
    

    
    </>
    
  )
}

settings.propTypes = {}

export default settings
