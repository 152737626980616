const passwordValidator = (values) =>{
    console.log(values)
   const regExp = /^(?=.*[a-z]){3,}(?=.*[A-Z]){2,}(?=.*[0-9]){2,}(?=.*[!@#$%^&*()--__+.]){1,}.{8,}$/
    let errors = '';
if(!values.password || !values.confirmPassword ){
    errors = " * required password fields"
}else{
    if(values.password !== values.confirmPassword ){
        errors = " * Both fields should be same"
    }
    else if(!values.password.length > 8 ){
            errors = " * password length should be graater than 8"
        }
        else if(!regExp.test(values.password)){
            errors = " * password must contain a capital letter and a special charachter"
        }
        else
        {
            console.log(values.password);
        }
    }

    return errors;
}
export default passwordValidator ;