import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import images from "../../utils/imageImports";
import axios from "axios";
import { AXIOS_BASE_URL, BASE_URL } from "../../actions/types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ShareSocial from "../../utils/ShareSocial";
import generateVCF from "../../utils/vcardGenerator2";
import getContrastTextColor from "../../utils/getTextColor";
// import webSocialShare from "../../utils/webShareSocial";
// const socialMediaData = [
//   { name: "Facebook", image: images.facebooksq },
//   { name: "X", image: images.twitterx },
//   { name: "Instagram", image: images.instagram },
//   { name: "LinkedIn", image: images.linkedin },
//   { name: "youtube", image: images.youtube },
//   { name: "snapchat", image: images.snapchat },
//   { name: "whatsApp", image: images.whatsapp },
//   { name: "email", image: images.email },
//   { name: "messenger", image: images.messenger },
//   // Add more social media items as needed
// ];
axios.defaults.baseURL = AXIOS_BASE_URL;
function PublicProfile() {
  const { username } = useParams();

  const [details, setDetails] = useState({
    cover: "",
    url: "",
    profile: "",
    name: "",
    designation: "",
    companyName: "",
    mobileNumber: "",
    email: "",
    socialDetails: [],
    title: "title",
    paragraph: "paragraph",
    background: "#222427",
  });
  const [gallery, setGallery] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true); // State for loading status
  const [textColor, setTextColor] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCopy = () => {
    navigator.clipboard.writeText(BASE_URL + "vyldfyre/profile/" + details.url);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make the API call using Axios
        const res = await axios.get(`vyldfyre/profile/${username}`);

        // Update the state with the fetched data
        console.log(res.data);
        const profile = res.data;
        console.log(profile);
        setDetails({
          cover: profile.cover_pic,
          url: username,
          profile: profile.profile_pic,
          name: profile.name,
          designation: profile.designation,
          companyName: profile.company_name,
          mobileNumber: profile.phn_number,
          email: profile.email,
          socialDetails: profile.socialLinks,
          title: profile.about_title,
          paragraph: profile.about_paragraph,
          background: profile.bg_theme ? profile.bg_theme : "#222427",
        });
        setGallery(profile.gallery);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        // Handle errors
        console.log(error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array, so this effect runs only once on mount
  useEffect(() => {
    // Calculate text color only when details.background changes
    const calculateTextColor = () => {
      if (checkType(details.background) === "This is a color") {
        const text = getContrastTextColor(details.background);
        setTextColor(text);
      }
    };
    calculateTextColor();
  }, [details.background]);
  // Render loading message if data is being fetched
  if (loading) {
    return (
      <div
        className="loading-container"
        role="status"
      >
        <img src={images.vyldfyreLogoAnimation} alt="loading" />

      </div>
    );
  }

  // Render component with fetched data

  console.log(details);
  function checkType(variable) {
    if (typeof variable === "string" && variable.match(/^#[0-9A-Fa-f]{3,6}$/)) {
      return "This is a color";
    } else if (variable.includes("linear")) {
      return "this is a gradient";
    }
  }
  let backgroundStyle = {};
  if (checkType(details.background) === "This is a color") {
    backgroundStyle = { backgroundColor: details.background };
  } else if (checkType(details.background) === "this is a gradient") {
    backgroundStyle = { background: details.background };
  } else {
    backgroundStyle = {
      backgroundImage: `url(${AXIOS_BASE_URL}uploads/${details.background})`,
    };
  }
  console.log(details);
  console.log(gallery);
  const renderImages = (source) => {
    return source.map((photo, index) => {
      // console.log(photo)
      return (
        <div key={index} className="cambg mb-2">
          {photo.id && photo.image_name ? (
            <>
              <img
                src={`${AXIOS_BASE_URL}uploads/${photo.image_name}`}
                className="imagebox img-fluid"
                alt="gallery"
              />
            </>
          ) : (
            <>
              <p className="text-dark">No Images to display</p>
            </>
          )}
        </div>
      );
    });
  };
  const contactInfo = {
    fullName: details.name,
    designation: details.designation,
    phoneNumber: details.mobileNumber,
    email: details.email,
  };

  const handleDownload = () => {
    generateVCF(contactInfo);
  };
  const getParagraphs = () => {
    return details.paragraph.split("\n"); // Split by newline characters
  };
  console.log(details.socialDetails)
  const sortedVal = details.socialDetails.slice().sort((a, b) => a.card_index - b.card_index);
  return (
    <>
      <div className="container-fluid">
        <div className=" flex-nowrap ">
          <div className="col ps-md-2 pt-2 ">
            <div className="row mt-2 justify-content-center align-items-center ">
              <div className="col-md-3 pt-3 ">
                <Modal show={show} onHide={handleClose}>
                  <div style={{ backgroundColor: "white" }}>
                    <Link
                      style={{ color: "black" }}
                      className="float-end mt-2 p-3"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <img src={images.closebtn} alt="close" />
                    </Link>
                    <Modal.Title
                      className="mx-3 mt-3"
                      style={{ color: "black" }}
                    >
                      <h4> Share this PROFILE</h4>
                    </Modal.Title>
                  </div>
                  <div
                    style={{ backgroundColor: "white" }}
                    className=" align-items-center justify-content-center  "
                  >
                    <Form className="text-center mx-3">
                      <div className="form-group">
                        <div id="profile" className="input-group">
                          <p className="text-dark"> Your Profile is live:</p>
                          <div className="input-container col-md-12">
                            <input
                              type="text"
                              style={{
                                color: "black",
                                backgroundColor: "#ffffff",
                                padding: "5px 12px 0px 115px",
                                border: "1px solid #C4C4C4",
                              }}
                              defaultValue={details.url}
                              // onChange={(e)=>urlChange(e)}
                            />
                            <label
                              className="filled"
                              style={{ color: "black" }}
                            >
                              vyldfyre/profile/
                            </label>
                          </div>
                          <Button
                            style={{
                              height: "40px",
                              borderRadius: "4px",
                              border: "1px solid #C4C4C4",
                              backgroundColor: "#ffffff",
                              color: "black",
                              top: "22px",
                            }}
                            onClick={handleCopy}
                            className="input-group-text browse btn"
                            variant="outline-secondary"
                            id="button-addon2"
                          >
                            copy url
                          </Button>
                        </div>
                      </div>

                      <div className=" align-items-center justify-content-center">
                        <ShareSocial details={details} />

                        <div className="col-md-11 mt-5 mb-5 ">
                          <Link
                            to="/register"
                            className="btn publicbtn px-5 align-items-center justify-content-center "
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              display: "inline",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            // onClick={(e)=>{handleUpdate(e)}}
                          >
                            Create Your Own Profile
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Modal>
                <div className="mobilecont1 mt-3">
                  <div
                    className="innrmediabox p-3"
                    style={{ ...backgroundStyle, color: textColor }}
                  >
                    <div className="coverpage d-flex  ">
                      {details.cover ? (
                        <img
                          src={`${AXIOS_BASE_URL}uploads/${details.cover}`}
                          alt="coverpage"
                          className="coverImage"
                        />
                      ) : (
                        <p className="mt-1 coverpage d-flex justify-content-center"></p>
                      )}
                    </div>
                    <div
                      className="contpage position-relative text-center"
                      //  style={backgroundStyle}
                    >
                      <div className="roundcam rounded-image-container ">
                        {details.profile ? (
                          <img
                            src={`${AXIOS_BASE_URL}uploads/${details.profile}`}
                            alt="profilepic"
                            className="rounded-image"
                          />
                        ) : (
                          <div>{details.name && details.name[0]}</div>
                        )}
                      </div>
                      <div className=" surname text-center pt-5">
                        <h4>{details.name}</h4>
                        <p>{details.designation}</p>
                        <p>{details.companyName}</p>
                        <p className="f11">{details.mobileNumber}</p>
                        <p className="pb-3">{details.email}</p>
                      </div>
                    </div>

                    {sortedVal.length &&
                      sortedVal.map((item, i) => {
                        if (item.socialmedia_name === "facebook") {
                          return (
                            <div
                              key={i}
                              className="social-media-card  justify-content-center w-100 mt-2 mb-2 "
                              style={{ marginTop: "-20px" }}
                            >
                              <Link
                                style={{ marginLeft: "10px" }}
                                to={item.socialmedia_link}
                                target="_blank"
                              >
                                <div className="social-media-item">
                                  <div className="row">
                                    <div className="col-1">
                                      <img
                                        className="social-media-logo me-3"
                                        style={{ color: "primary" }}
                                        src={images.facebooksq}
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-10 text-center">
                                      facebook
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        } else if (item.socialmedia_name === "instagram") {
                          return (
                            <div
                              key={i}
                              className="social-media-card justify-content-center w-100 mt-2 mb-2 "
                              style={{ marginTop: "-20px" }}
                            >
                              <Link
                                style={{ marginLeft: "10px" }}
                                to={item.socialmedia_link}
                                target="_blank"
                              >
                                <div className="social-media-item ">
                                  <div className="row">
                                    <div className="col-1">
                                      <img
                                        className="social-media-logo me-3"
                                        style={{ color: "primary" }}
                                        src={images.instagram}
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-10 text-center">
                                      Instagram
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        } else if (item.socialmedia_name === "linkedin") {
                          return (
                            <div
                              key={i}
                              className="social-media-card  justify-content-center w-100 mt-2 mb-2 "
                              style={{ marginTop: "-20px" }}
                            >
                              <Link
                                style={{ marginLeft: "10px" }}
                                to={item.socialmedia_link}
                                target="_blank"
                              >
                                <div className="social-media-item ">
                                  <div className="row">
                                    <div className="col-1">
                                      <img
                                        className="social-media-logo me-3"
                                        style={{ color: "primary" }}
                                        src={images.linkedin}
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-10 text-center">
                                      linkedIn
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        } else if (item.socialmedia_name === "youtube") {
                          return (
                            <div
                              key={i}
                              className="social-media-card justify-content-center w-100 mt-2 mb-2 "
                              style={{ marginTop: "-20px" }}
                            >
                              <Link
                                style={{ marginLeft: "10px" }}
                                to={item.socialmedia_link}
                                target="_blank"
                              >
                                <div className="social-media-item ">
                                  <div className="row">
                                    <div className="col-1">
                                      <img
                                        className="social-media-logo me-3"
                                        style={{ color: "primary" }}
                                        src={images.youtube}
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-10 text-center">
                                      Youtube
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        } else if (item.socialmedia_name === "twitter") {
                          return (
                            <div
                              key={i}
                              className="social-media-card  justify-content-center w-100 mt-2 mb-2 "
                              style={{ marginTop: "-20px" }}
                            >
                              <Link
                                style={{ marginLeft: "10px" }}
                                to={item.socialmedia_link}
                                target="_blank"
                              >
                                <div className="social-media-item ">
                                  <div className="row">
                                    <div className="col-1">
                                      <img
                                        className="social-media-logo "
                                        style={{ color: "primary" }}
                                        src={images.twitterx}
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-10 text-center">
                                      Twitter
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        }
                      })}
                    <p className="mt-3 ">About</p>
                    <div className=" innrmediabox2 bio text-start p-3 mt-2 mb-2 f12">
                      <h6 style={{ color: "black" }}>{details.title}</h6>

                      {getParagraphs().map((paragraph, index) => (
                        <>
                          <p
                            key={index}
                            style={{ fontSize: "14px", color: "black" }}
                          >
                            {paragraph}
                          </p>
                          <br />
                        </>
                      ))}
                    </div>
                    {/* <webSocialShare /> */}
                    <p className="mt-3 ">Work Gallery</p>
                    <div className=" mt-3 mb-5">
                      {gallery.length && renderImages(gallery)}
                    </div>
                    <p className="text-center mt-5 ff14">
                      Powered by{" "}
                      <a
                        href="https://vyldfyre.com/"
                        target="_blank"
                        style={{ color: "#FFB400" }}
                      >
                        VYLDFYRE
                      </a>{" "}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 mb-1">
                    <Link className="btn publicbtn" onClick={handleShow}>
                      <div>
                        <p style={{ display: "inline" }}>Share this</p>{" "}
                        <p style={{ display: "inline", color: "#FFB400" }}>
                          PROFILE
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <Link className="btn publicbtn" onClick={handleDownload}>
                      <div>
                        <p style={{ display: "inline", color: "#FFB400" }}>
                          Add to Contact
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-12 mt-2  align-items-center justify-content-center">
                  <Link
                    to="/register"
                    className="btn publicbtn"

                    // onClick={(e)=>{handleUpdate(e)}}
                  >
                    Create your own{" "}
                    <p style={{ display: "inline", color: "#FFB400" }}>
                      VYLDFYRE
                    </p>{" "}
                    Profile
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicProfile;
