import React from "react";
import PrivateRoutes from "./privateRoutes";
import Miniwebsite from "../components/dashboard/miniwebsite/miniwebsite";
import MiniStore from "../components/dashboard/ministore/ministore";
import Settings from "../components/dashboard/settings/settings";
import Analytics from "../components/dashboard/analytics/analytics";
import BasicDetails from "../components/dashboard/BasicDetails";
import SocialDetails from "../components/dashboard/socialDetails";
import Gallery from "../components/dashboard/Gallery";
import Cart from "../components/shop/Cart";
import Checkout from "../components/shop/Checkout";
function AuthenticatedRoutes() {
  return (
    <>
      {/* <PrivateRoutes  path='/profile' element={<Profile/>} /> */}
      <PrivateRoutes path="/miniwebsite" element={<Miniwebsite />} />
      <PrivateRoutes path="/analytics" element={<Analytics />} />
      <PrivateRoutes path="/settings" element={<Settings />} />
      <PrivateRoutes path="/ministore" element={<MiniStore />} />
      <PrivateRoutes path="/profile" element={<BasicDetails />} />
      <PrivateRoutes
        path="/profile/social-details"
        element={<SocialDetails />}
      />
      <PrivateRoutes path="/profile/gallery" element={<Gallery />} />
      <PrivateRoutes path= "/vyldfyrecard/cart" element={<Cart />} />
      <PrivateRoutes path="/vyldfyrecard/checkout" element={<Checkout />} />
    </>
  );
}

export default AuthenticatedRoutes;
