
import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import { Link } from "react-router-dom";
import { AXIOS_BASE_URL } from "../../../actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { deleteImages } from "../../../actions/profile";
import { deleteMultipleImages } from "../../../actions/profile";
import { setAlert } from "../../../actions/setAlert";
axios.defaults.baseURL = AXIOS_BASE_URL;

function ManageGallery({images,deleteImages ,setAlert, deleteMultipleImages,...props}) {
    const [gallery, setGallery] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [isChecked, setisChecked] = useState([]);
    // const [selectedImages, setSelectedImages] = useState([]);
    useEffect(() => {
      setGallery(images);
    }, [images]);


    const handleDelete = (index, image) => {
        // console.log(index)
        if (image.id) {
          console.log(index);
          deleteImages(image.id);
          const delval = [...gallery];
          delval.splice(index, 1);
          setGallery(delval);
        } else {
          const delval = [...gallery];
          delval.splice(index, 1);
          setGallery(delval);
        }
      };

    const handlecheckbox = (e) => {
        const { value, checked } = e.target;
        console.log(value);
        if (checked) {
          setisChecked([...isChecked, value]);
        } else {
          setisChecked(isChecked.filter((e) => e !== value));
        }
      };
// console.log(isChecked);
const alldelete = async (e) => {
    e.preventDefault();
    // console.log(isChecked);
    if (isChecked.length !== 0) {
    
      deleteMultipleImages(isChecked);
     
    } else {
      alert("please Select at least one check box !");
    }
  };

    const renderImages = (source) => {
        //console.log(source)
    
        return source.map((photo, index) => {
          //   console.log(photo)
          return (
            <div key={index} className="col-md-3 col-12 position-relative">
              {photo.id && photo.image_name ? (
                
                  <div className="image-display-card" >
                    <Image
                      src={`${AXIOS_BASE_URL}uploads/${photo.image_name}`}
                      className="image-display"
                      thumbnail
                    />
                    <div className="col-md">
                      {toggle ? (
                        <div
                          className="form-check float-end"
                          style={{ position: "absolute", marginTop: "20px" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={photo.id}
                            id="flexCheckDefault"
                            onChange={(e) => handlecheckbox(e)}
                          />
                        </div>
                      ) : (
                        <div
                          className=" float-end hover"
                          style={{ position: "absolute", marginTop: "20px" }}
                        >
                          <Link
                            onClick={() => handleDelete(index, photo)}
                            className="small-round-button"
                          >
                            <FontAwesomeIcon
                              style={{ color: "black" }}
                              icon={faTrashCan}
                            />
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
              
              ) : (
                <div>
                  <Col>
                    <Image src={photo} className="image-display" thumbnail />
                    <Link
                      onClick={() => handleDelete(index)}
                      className="small-round-button "
                    >
                      <FontAwesomeIcon
                        style={{ color: "black" }}
                        icon={faTrashCan}
                      />
                    </Link>
                  </Col>
                </div>
              )}
            </div>
          );
        });
      };
  return (
    <div>
      <Modal {...props} size="xl" className="custom-modal">
        <div data-bs-theme="dark" className="text-end">
          <CloseButton onClick={props.onHide} />
        </div>
        <Modal.Body>
          <div className="align-items-center float-center justify-content-center">
            <div>
              <Container>
                <Row>
                  <Col>
                    <Modal.Title className="mb-3">Manage Gallery</Modal.Title>
                  </Col>
                  <Col md="auto">
                    <Button
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                      variant="secondary"
                    >
                      select Images
                    </Button>{" "}
                    <Button variant="secondary">+ Add Images</Button>{" "}
                    <Button variant="secondary">
                      <FontAwesomeIcon
                        className="text-white"
                        icon={faTrashCan}
                        onClick={(e) => {
                          alldelete(e);
                        }}
                      />
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
           <Row> {renderImages(gallery)}</Row>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
ManageGallery.propTypes =  {
deleteImages: PropTypes.func.isRequired,
deleteMultipleImages: PropTypes.func.isRequired,
setAlert: PropTypes.func.isRequired
};
export default connect(null,{deleteImages,setAlert,deleteMultipleImages})( ManageGallery);