import { v4 as uuidv4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from "./types";
 

export const setAlert = (msg, alertType) => dispatch => {
   const uniqueId = uuidv4();
   //const id = uuid.v4();
 dispatch({
    type: SET_ALERT,
    payload :{ msg, alertType, uniqueId}
 })
 setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: uniqueId }), 5000);
}