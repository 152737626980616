import React from "react";
import images from "../../utils/imageImports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
function ShopHeader() {
  return (
    <div>
  <div>
    <div className="container mb-5">
      <div className="row align-items-center">
        <div className="col-md-5 pt-3 my-4 my-md-0">
          <Link href="index.html">
            <img src={images.logo} className="img-fluid" alt="" />
          </Link>
        </div>
        <div id="dashbrdetails" className="col-md-7 pt-3 ">
          <ul className="nav flex-row justify-content-end">
            <li className="nav-item">
              <Link className="nav-link">
                <FontAwesomeIcon className="me-3 " icon={faCircleQuestion} />
                Call for Help<br/><a className="yellowtext"> +91-40001010010</a>
                
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" href="#">
                <FontAwesomeIcon icon={faUser} />
                My Account
              </Link>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <FontAwesomeIcon icon={faCartShopping} />
                Cart
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default ShopHeader;
