import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ManageGallery from "./popups/ManageGallery";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Footer from "../layout/Footer";
import { Button } from "react-bootstrap";
import Sidebar from "../layout/sidebar";
import { BASE_URL } from "../../actions/types";
import axios from "axios";
import { getGallery } from "../../actions/profile";
import { AXIOS_BASE_URL } from "../../actions/types";
import { GalleryContext } from "../../utils/providers/RootProvider";
import Mobile from "./Mobile";
import { setAlert } from "../../actions/setAlert";

function Gallery({ gallery, auth: { user }, getGallery, setAlert }) {
 
  const [images, setimages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [galleryPreview, setGalleryPreview] = useState(false);
  const [url, setUrl] = useState(
    user.username && user ? user.username : user.googleId
  );

  // const getDataFromManageGallery = (data) => {
  //   //console.log(data);
  //   setimages(data);
  // };
  const { setData: setGalleryData } = useContext(GalleryContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("users/gallery");
        console.log(res.data);
        setimages(res.data.gallery);
        // Handle response data here
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    setGalleryData([...images, ...selectedImages]);
  }, [ images,selectedImages]);

  const imageHandleChange = (e) => {
    const files = e.target.files;
    console.log(files);
    if (files.length > 0) {
      setSelectedImages([...selectedImages, ...files]);
    }
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(BASE_URL + "vyldfyre/profile/" + url);
  };
  const onDelete = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  const renderImages = (source) => {
    return source.map((photo, index) => {
      return (
        <Col xs={3} key={index} md={2}>
          <div className="image-card">
            <img
              src={URL.createObjectURL(photo)}
              alt="Uploaded"
              className="image"
            />
            <button onClick={onDelete} className="delete-btn">
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </Col>
      );
    });
  };
  const handleUpdate = async () => {
    const formData = new FormData();
    console.log(selectedImages);
    selectedImages.forEach((file, index) => {
      formData.append(`gallery`, file);
    });
    console.log(formData);
    try {
      const res = await axios.post(`${AXIOS_BASE_URL}users/gallery`, formData);
      console.log(res);
    setAlert(res.data.message, "success");
      const res1 = await axios.get("users/gallery");
      console.log(res1.data);
      setimages(res1.data.gallery);
      setSelectedImages([]);
    } catch (error) {
      console.log(error);
      setAlert(error.message, "danger");
    }
    // console.log(selectedImages);
  };
   
  const setManageGallery = async ()=>{
    try {
      const res = await axios.get("users/gallery");
      console.log(res.data);
      setimages(res.data.gallery);
      setGalleryPreview(true)
    } catch (error) {
      console.log(error);
    }

  }
  return (
    <>
      <div id="profile" className="container">
        <div className="row pt-3">
          <div className="col-md-3 mb-4">
            <Sidebar />
          </div>
          <div className="col-md-6 pt-2 midcontainer">
            <div  className="input-group">
              <p className="pb-2">Your Profile is live: </p>
              <div className="input-container">
                <input
                  type="text"
                  style={{
                    color: "white",
                  }}
                  defaultValue={url}
                  //  onChange={(e) => urlChange(e)}
                />
                <label className="filled">vyldfyre/profile/</label>
              </div>
              <button
                onClick={handleCopy}
                className="input-group-text2 browse btn"
                variant="outline-secondary"
                id="button-addon2"
              >
                copy url
              </button>
            </div>

            <div className="row mt-5 mb-3">
              <div className="col-md-6 col-4 ">
                
                <p className="subtitle">Gallery</p>
              </div>
              <div className="col-md-3 col-4 text-md-end">
                <div className="input--file">
                  <a className="linkyellow1" onClick={imageHandleChange}>
                    <FontAwesomeIcon icon={faDownload} />
                    Upload Image
                  </a>
                  <input
                    name="Select File"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    multiple
                    onChange={imageHandleChange}
                  />
                </div>
              </div>
              <div className="col-md-3 col-4 text-md-end">
                <Link
                  onClick={() => {setManageGallery()}}
                  className="linkyellow"
                >
                  Manage Gallery &nbsp;&nbsp;
                </Link>
              </div>
            </div>

            <div className="innrmediabox">
              {selectedImages.length ? (
                <div className="uploadimg d-flex flex-wrap align-items-start justify-content-start">
                  <Container>
                    <Row>{renderImages(selectedImages)}</Row>
                  </Container>
                </div>
              ) : (
                <div className="uploadimg d-flex flex-column align-items-center justify-content-center">
                  <span className="text-white">Add images to the gallery</span>
                </div>
              )}
            </div>

            <ManageGallery
              show={galleryPreview}
              images={images}
              // senddataToGallery={getDataFromManageGallery}
              onHide={() => setGalleryPreview(false)}
            />

            <Button
              className="login mt-2 mb-2 p-2 float-end hover"
              onClick={handleUpdate}
            >
              Save
            </Button>
          </div>

          <Mobile />
          <Footer />
        </div>
      </div>
    </>
  );
}
Gallery.propTypes = {
  auth: PropTypes.object.isRequired,
  getGallery: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { getGallery, setAlert })(Gallery);
