import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Routes,Route,Navigate } from 'react-router-dom'

//const navigate = useNavigate('');

const PrivateRoutes = ({path,element,auth :{ user, isAuthenticated,loading}})=> {
return !loading && (isAuthenticated ? <Routes><Route path={path} element={element} /></Routes> : <Navigate to='/login' />);

}
PrivateRoutes.propTypes = {
    auth: PropTypes.object.isRequired
}
const mapStateToProps= state=>({
    auth: state.auth
});
export default connect(mapStateToProps)(PrivateRoutes)
